/* zobrazení filtru */
document.addEventListener('DOMContentLoaded', function () {
  const filterButton = document.querySelector('.js-filter-terms-button');
  const filterWrapper = document.querySelector('.js-filter-terms-block');
  const filterButtonClose = document.querySelector('.js-filter-terms-button-close');
  const menuTopContainer = document.querySelector('.sticky-menu-container');
  const menuTop = document.querySelector('.sticky-menu');
  const column2 = document.querySelector('.js-top-block-column-2');
  const column3 = document.querySelector('.js-top-block-column-3');
  const column4 = document.querySelector('.js-top-block-column-4');
  const termsEraser = document.querySelector('.js-terms-eraser');
  const textInfo = document.querySelector('.js-filter-info');
  const textInfoSource = document.querySelector('.js-result-text');
  const buttonBack = document.querySelector('.js-button-back');

  if (filterButton) {
    const handleFilter = function (e) {
      e.preventDefault();
      document.body.classList.add('filter-sticky-bg');
      filterWrapper.classList.add('show');
      menuTopContainer.classList.add('z-index-0');
      menuTop.classList.add('z-index-0');
      if (termsEraser) {
      const activeTerms = document.querySelectorAll('span[data-filter-term][data-filter-term-status="true"]');
      if (activeTerms.length > 1) {
        if (termsEraser.classList.contains('d-none')) {
          termsEraser.classList.remove('d-none');
        }
      } else {
        if (!termsEraser.classList.contains('d-none')) {
          termsEraser.classList.add('d-none');
        }
      }
    }

      if (textInfo && textInfoSource) {
        textInfo.innerHTML = textInfoSource.getAttribute('data-text');
      }

      // Přesun focusu na první prvek
      const closeButton = filterWrapper.querySelector('.js-filter-start-focus');
      if (closeButton) {
        setTimeout(() => {
          closeButton.focus();
        }, 100);
      }
    };

    filterButton.addEventListener('click', handleFilter);
    filterButton.addEventListener('keydown', function (e) {
      if (e.key === 'Enter') {
        handleFilter(e);
      }
    });
  }

  const handleClose = function (e) {
    const closeButton = e.target.closest('.js-filter-terms-button-close');
    if (closeButton && (e.type === 'click' || e.key === 'Enter')) {
      e.preventDefault();
      document.body.classList.remove('filter-sticky-bg');
      filterWrapper.classList.remove('show');
      menuTopContainer.classList.remove('z-index-0');
      menuTop.classList.remove('z-index-0');
      column2.classList.add('d-none');
      column3.classList.add('d-none');
      column4.classList.add('d-none');
      buttonBack.classList.add('d-none');
    }
  };

  filterWrapper.addEventListener('click', handleClose);
  filterWrapper.addEventListener('keydown', handleClose);

});

// zobrazemí nejčasteji filtrovanych kategorií - uspusobeni pro mobil i desktop
document.addEventListener('DOMContentLoaded', function () {
  const mostFiltered = document.querySelector('.most-filtered');
  const filterButton = document.querySelector('.js-filter-frequently-categories');
  const targetBlock = document.querySelector('.js-links-frequently-categories');
  const filterSideBlock = document.querySelector('.filter--side-block');

  if (mostFiltered) {
    if (filterButton && targetBlock) {
      filterButton.addEventListener('click', function (e) {
        e.preventDefault();
        mostFiltered.querySelector('.title').classList.toggle('invert');
        filterSideBlock.classList.toggle('invert');
        this.classList.toggle('invert');
        targetBlock.style.display = targetBlock.style.display === 'block' ? 'none' : 'block';
      });
    }
  }

  window.addEventListener('resize', function () {
    if (typeof resizeResetClassStyle === 'function') {
      resizeResetClassStyle(0, 750, '.js-title', 'invert', 0);
      resizeResetClassStyle(0, 750, '.filter--side-block', 'invert', 0);
      resizeResetClassStyle(0, 750, '.js-filter-frequently-categories', 'invert', 0);
      resizeResetClassStyle(0, 750, '.js-links-frequently-categories', 0, 'display');
    }
  });
});

/* smazani všech klíčových slov */
document.addEventListener('DOMContentLoaded', function () {
  const removeAllButtonKeywords = document.querySelector('.js-remove-all-keywords');

  if (removeAllButtonKeywords) {
    removeAllButtonKeywords.addEventListener('click', function (e) {
      e.preventDefault();

      const keywordSpans = document.querySelectorAll('span[data-filter-keyword]');
      const keywordHidden = document.querySelectorAll('.js-keywords');
      const keywordVisibleNone = document.querySelector('.js-keywords-none');

      keywordSpans.forEach(span => {
        span.setAttribute('data-filter-keyword-status', 'false');
        span.parentElement.classList.add('d-none');
      });
      keywordHidden.forEach(div => {
        div.classList.add('d-none');
      });
      keywordVisibleNone.classList.remove('d-none');
    });
  }
});

/* smazani jednotlivých klíčových slov  */
document.addEventListener('DOMContentLoaded', function () {
  document.addEventListener('click', function (e) {
    const targetSpan = e.target.matches('span[data-filter-keyword]')
      ? e.target
      : e.target.matches('.icon')
        ? e.target.closest('span[data-filter-keyword]')
        : null;

    if (targetSpan) {
      targetSpan.setAttribute('data-filter-keyword-status', 'false');
      targetSpan.closest('li').classList.add('d-none');

      // Kontrola počtu aktivních filtrů
      const activeFilters = document.querySelectorAll('span[data-filter-keyword][data-filter-keyword-status="true"]');
      const keywordsEraser = document.querySelector('.filter--keywords-eraser');
      const keywords = document.querySelectorAll('.js-keywords');
      const keywordsNone = document.querySelector('.js-keywords-none');

      if (activeFilters.length === 1) {
        if (keywordsEraser) {
          keywordsEraser.classList.add('d-none');
        }
      } else if (activeFilters.length === 0) {
        keywords.forEach(k => k.classList.add('d-none'));
        if (keywordsNone) {
          keywordsNone.classList.add('d-none');
        }
      }
    }
  });
});

/* smazani všech kategorii */
document.addEventListener('DOMContentLoaded', function () {
  const removeAllButtonTerms = document.querySelector('.js-remove-all-terms');

  if (removeAllButtonTerms) {
    removeAllButtonTerms.addEventListener('click', function (e) {
      e.preventDefault();

      const termsSpans = document.querySelectorAll('span[data-filter-term]');
      const termsHidden = document.querySelectorAll('.js-terms');
      const termsVisibleNone = document.querySelector('.js-terms-none');
      const termsClearAll = document.querySelector('.js-terms-eraser');
      const bodyTermsAll = document.querySelectorAll('.js-body-terms div[class^="js-t-"]');
      const termsCheckboxClearAll = document.querySelectorAll('.js-filter-checkbox');
      const termsCheckboxLiClearAll = document.querySelectorAll('.js-item-checkbox');
      const clvl_2 = document.querySelector('.js-top-block-column-2');
      const clvl_3 = document.querySelector('.js-top-block-column-3');
      const clvl_4 = document.querySelector('.js-top-block-column-4');
      const buttonBack = document.querySelector('.js-button-back');

      clvl_2.classList.add('d-none');
      clvl_3.classList.add('d-none');
      clvl_4.classList.add('d-none');
      buttonBack.classList.add('d-none');
      buttonBack.setAttribute('data-level', '1');
      document.querySelectorAll('.js-tbc-1-filter li, .js-tbc-2-filter li, .js-tbc-3-filter li, .js-tbc-4-filter li').forEach(li => li.classList.remove('toggle'));

      termsSpans.forEach(span => {
        span.setAttribute('data-filter-term-status', 'false');
        span.parentElement.classList.add('d-none');
      });
      termsHidden.forEach(div => {
        div.classList.add('d-none');
      });
      bodyTermsAll.forEach(div => {
        div.classList.add('d-none');
      });
      termsVisibleNone.classList.remove('d-none');
      termsClearAll.classList.add('d-none');
      termsCheckboxClearAll.forEach(checkbox => {
        checkbox.checked = false;
        checkbox.setAttribute('aria-checked', 'false');
      });
      termsCheckboxLiClearAll.forEach(li => {
        li.classList.remove('active');
      });
    });
  }
});

/* smazani jednotlivych kategorii */
document.addEventListener('DOMContentLoaded', function () {
  document.addEventListener('click', function (e) {
    const targetSpan = e.target.matches('span[data-filter-term]')
      ? e.target
      : e.target.matches('.icon')
        ? e.target.closest('span[data-filter-term]')
        : null;

    const termId = targetSpan?.hasAttribute('data-filter-term-id')
      ? targetSpan.getAttribute('data-filter-term-id')
      : null;

    if (targetSpan) {
      targetSpan.setAttribute('data-filter-term-status', 'false');
      targetSpan.closest('li').classList.add('d-none');

      const mainCategory = targetSpan.getAttribute('data-filter-terms-main');
      const activeTerms = document.querySelectorAll('span[data-filter-term][data-filter-term-status="true"]');
      const termsEraser = document.querySelector('.js-terms-eraser');
      const terms = document.querySelectorAll('.js-terms');
      const termsNone = document.querySelector('.js-terms-none');

      const activeInCategory = document.querySelectorAll(`span[data-filter-terms-main="${mainCategory}"][data-filter-term-status="true"]`);
      if (activeInCategory.length === 0) {
        const categoryWrapper = document.querySelector(`.js-${mainCategory}`);
        categoryWrapper?.classList.add('d-none');
      }

      if (activeTerms.length === 1) {
        termsEraser?.classList.add('d-none');
      } else if (activeTerms.length === 0) {
        terms.forEach(t => t.classList.add('d-none'));
        termsNone?.classList.remove('d-none');
      }

      if (termId !== null) {
        const checkbox = document.querySelector(`input[data-term-id="${termId}"]`);
        if (checkbox) {
          checkbox.checked = false;
          checkbox.setAttribute('aria-checked', 'false');
          const parentLi = checkbox.closest('li');
          if (parentLi && parentLi.classList.contains('active')) {
            parentLi.classList.remove('active');
          }
        }
      }

      const visibleCategories = document.querySelectorAll('.js-body-terms .js-terms li:not(.d-none)');
      const keywordClearAll = document.querySelector('.js-terms-eraser');
      const keywordVisibleNone = document.querySelector('.js-terms-none');
      const keywordHidden = document.querySelectorAll('.tbc-1-subtitle.js-terms');

      if (visibleCategories.length == 0) {
        keywordHidden.forEach(div => {
          div.classList.add('d-none');
        });
        keywordVisibleNone.classList.remove('d-none');
        keywordClearAll.classList.add('d-none');
      } else if (visibleCategories.length > 1) {
        keywordClearAll?.classList.remove('d-none');
      } else {
        keywordClearAll?.classList.add('d-none');
      }
    }
  });
});

/* pokud odeslu filtr, tak se zobrazí výsledky */
document.addEventListener('DOMContentLoaded', function () {
  const doneButton = document.querySelector('.js-filter-done');
  const closeButton = document.querySelector('.js-filter-terms-button-close');
  const buttonBack = document.querySelector('.js-button-back');

  function handleDone(e) {
    if (e.type === 'keydown' && e.key === 'Enter' || e.type === 'click') {
      e.preventDefault();

      // Sestavení query pro slova
      const activeKeywordValues = Array.from(
        document.querySelectorAll('span[data-filter-keyword][data-filter-keyword-status="true"]')
      ).map(span => span.getAttribute('data-filter-keyword'));
      const queryString = '&q=' + activeKeywordValues.join('+');

      // Sestavit query pro kategorie
      const activeTermValues = Array.from(
        document.querySelectorAll('span[data-filter-term][data-filter-term-status="true"]')
      );
      const groupedTerms = activeTermValues.reduce((acc, term) => {
        const main = term.getAttribute('data-filter-terms-main');
        if (!acc[main]) acc[main] = [];
        acc[main].push(term.getAttribute('data-filter-term-id'));
        return acc;
      }, {});
      let queryStringTerms = '';
      Object.entries(groupedTerms).forEach(([main, ids]) => {
        ids.forEach((id, index) => {
          queryStringTerms += `&${main}[${index}]=${id}`;
        });
      });

      const baseUrl = window.location.href.split('?')[0];
      const newUrl = baseUrl + '?pg=1' + queryString + queryStringTerms;
      window.location.href = newUrl;
    } else if (e.key === 'Tab' && !e.shiftKey && buttonBack.classList.contains('d-none')) {
      // jakmile dojedu tabulatorem na tlacitko pouzit filtr tak se presunu na tlacitko zavrit
      e.preventDefault();
      closeButton.focus();
    }

  }

  if (doneButton) {
    // Přidání event listenerů
    doneButton.addEventListener('click', handleDone);
    doneButton.addEventListener('keydown', handleDone);
  }
});

// zpetný pohyb v filtrech na mobilu
document.addEventListener('DOMContentLoaded', function () {
  const buttonBack = document.querySelector('.js-button-back');
  const closeButton = document.querySelector('.js-filter-terms-button-close');
  const focusButton = document.querySelector('.js-filter-start-focus');

  function handleBack(e) {
    if (e.type === 'click' || (e.type === 'keydown' && e.key === 'Enter')) {

      const currentLevel = parseInt(buttonBack.getAttribute('data-level'));

      if (currentLevel > 1) {
        // Skrýt aktuální sloupec
        const currentColumn = document.querySelector(`.js-top-block-column-${currentLevel}`);
        if (currentColumn) {
          currentColumn.classList.add('d-none');
          currentColumn.querySelectorAll('.js-item-checkbox').forEach(li => {
            li.classList.remove('toggle');
          });
        }
        const currentColumnPrev = document.querySelector(`.js-top-block-column-${currentLevel - 1}`);
        if (currentColumnPrev) {
          currentColumnPrev.querySelectorAll('.js-item-checkbox').forEach(li => {
            li.classList.remove('toggle');
          });
        }

        // Snížit level
        const newLevel = currentLevel - 1;
        buttonBack.setAttribute('data-level', newLevel);
        buttonBack.focus();

        if (newLevel === 1) {
          buttonBack.classList.add('d-none');
          focusButton.focus();
        }
      }
      if (currentLevel === 1) {
        buttonBack.classList.add('d-none');
        focusButton.focus();
      }
    } else if (e.key === 'Tab' && !e.shiftKey) {
      // jakmile dojedu tabulatorem na tlacitko pouzit filtr tak se presunu na tlacitko zavrit
      e.preventDefault();
      closeButton.focus();
    }
  }

  if (buttonBack) {
    buttonBack.addEventListener('click', handleBack);
    buttonBack.addEventListener('keydown', handleBack);
  }
});

/* reseni level_1, level_2, level_3, level_4 - reaguje na klik, tab, enter */
document.addEventListener('DOMContentLoaded', function () {
  const column2 = document.querySelector('.js-top-block-column-2');
  const column3 = document.querySelector('.js-top-block-column-3');
  const column4 = document.querySelector('.js-top-block-column-4');
  const buttonBack = document.querySelector('.js-button-back');

  function handleLevel1(e) {
    if (e.type === 'keydown' && e.key !== 'Enter') return;
    if (e.target.matches('.js-level_1, .js-level_1 label, .js-level_1 span')) {
      e.preventDefault();

      const levelElement = e.target.closest('.js-level_1');
      if (!levelElement) return;

      document.querySelectorAll('.js-tbc-1-filter li, .js-tbc-2-filter li, .js-tbc-3-filter li, .js-tbc-4-filter li').forEach(li => li.classList.remove('toggle'));

      // Přidat toggle k aktuálnímu li
      const currentLi = levelElement.closest('li');
      if (currentLi) {
        currentLi.classList.toggle('toggle');
      }

      const name = levelElement.getAttribute('data-name');
      const submenu = levelElement.getAttribute('data-submenu');

      if (submenu) {
        // Najít elementy
        const titleSpan = document.querySelector('.js-tbc-2-title');
        const filterList = document.querySelector('.js-tbc-2-filter');

        // Odstranit d-none
        column2.classList.remove('d-none');
        buttonBack.classList.remove('d-none');
        buttonBack.setAttribute('data-level', '2');

        // pridat d-none
        column3.classList.add('d-none');
        column4.classList.add('d-none');

        // Vložit název
        titleSpan.textContent = name;

        // Vložit submenu z localStorage
        const submenuHtml = localStorage.getItem(submenu);
        if (submenuHtml) {
          filterList.innerHTML = submenuHtml;
        }

        // Po načtení submenu zkontrolovat aktivní štítky
        const activeTerms = document.querySelectorAll('.js-body-terms span[data-filter-term-status="true"]');

        activeTerms.forEach(term => {
          const termId = term.getAttribute('data-filter-term-id');
          const checkbox = filterList.querySelector(`input[data-term-id="${termId}"]`);
          if (checkbox) {
            checkbox.checked = true;
            checkbox.setAttribute('aria-checked', 'true');
            const parentLi = checkbox.closest('li');
            if (parentLi) {
              parentLi.classList.add('active');
            }
          }
        });
      }
    }
  }

  function handleLevel2(e) {
    if (e.type === 'keydown' && e.key !== 'Enter') return;
    if (e.target.matches('.js-level_2, .js-level_2 label, .js-level_2 span, .js-enter-level_2')) {
      e.preventDefault();
      var levelElement;

      if (e.target.classList.contains('js-enter-level_2')) {
        levelElement = e.target.querySelector('.js-level_2');
      } else {
        levelElement = e.target.closest('.js-level_2');
      }
      if (!levelElement) return;

      document.querySelectorAll('.js-tbc-2-filter li, .js-tbc-3-filter li, .js-tbc-4-filter li').forEach(li => li.classList.remove('toggle'));

      // Přidat toggle k aktuálnímu li
      const currentLi = levelElement.closest('li');
      if (currentLi) {
        currentLi.classList.toggle('toggle');
      }

      const name = levelElement.getAttribute('data-name');
      const submenu = levelElement.getAttribute('data-submenu');

      if (submenu) {
        // Najít elementy
        const titleSpan = document.querySelector('.js-tbc-3-title');
        const filterList = document.querySelector('.js-tbc-3-filter');

        // Odstranit d-none
        column3.classList.remove('d-none');

        // pridat d-none
        column4.classList.add('d-none');
        buttonBack.classList.remove('d-none');
        buttonBack.setAttribute('data-level', '3');

        // Vložit název
        titleSpan.textContent = name;

        // Vložit submenu z localStorage
        const submenuHtml = localStorage.getItem(submenu);
        if (submenuHtml) {
          filterList.innerHTML = submenuHtml;
        }

        // Po načtení submenu zkontrolovat aktivní štítky
        const activeTerms = document.querySelectorAll('.js-body-terms span[data-filter-term-status="true"]');

        activeTerms.forEach(term => {
          const termId = term.getAttribute('data-filter-term-id');
          const checkbox = filterList.querySelector(`input[data-term-id="${termId}"]`);
          if (checkbox) {
            checkbox.checked = true;
            checkbox.setAttribute('aria-checked', 'true');
            const parentLi = checkbox.closest('li');
            if (parentLi) {
              parentLi.classList.add('active');
            }
          }
        });
      }
    }
  }

  function handleLevel3(e) {
    if (e.type === 'keydown' && e.key !== 'Enter') return;
    if (e.target.matches('.js-level_3, .js-level_3 label, .js-level_3 span, .js-enter-level_3')) {
      e.preventDefault();
      var levelElement;

      if (e.target.classList.contains('js-enter-level_3')) {
        levelElement = e.target.querySelector('.js-level_3');
      } else {
        levelElement = e.target.closest('.js-level_3');
      }
      if (!levelElement) return;

      document.querySelectorAll('.js-tbc-3-filter li, .js-tbc-4-filter li').forEach(li => li.classList.remove('toggle'));

      // Přidat toggle k aktuálnímu li
      const currentLi = levelElement.closest('li');
      if (currentLi) {
        currentLi.classList.toggle('toggle');
      }

      const name = levelElement.getAttribute('data-name');
      const submenu = levelElement.getAttribute('data-submenu');

      if (submenu) {
        // Najít elementy
        const titleSpan = document.querySelector('.js-tbc-4-title');
        const filterList = document.querySelector('.js-tbc-4-filter');

        // Odstranit d-none
        column4.classList.remove('d-none');
        buttonBack.classList.remove('d-none');
        buttonBack.setAttribute('data-level', '4');

        // Vložit název
        titleSpan.textContent = name;

        // Vložit submenu z localStorage
        const submenuHtml = localStorage.getItem(submenu);
        if (submenuHtml) {
          filterList.innerHTML = submenuHtml;
        }

        // Po načtení submenu zkontrolovat aktivní štítky
        const activeTerms = document.querySelectorAll('.js-body-terms span[data-filter-term-status="true"]');

        activeTerms.forEach(term => {
          const termId = term.getAttribute('data-filter-term-id');
          const checkbox = filterList.querySelector(`input[data-term-id="${termId}"]`);
          if (checkbox) {
            checkbox.checked = true;
            checkbox.setAttribute('aria-checked', 'true');
            const parentLi = checkbox.closest('li');
            if (parentLi) {
              parentLi.classList.add('active');
            }
          }
        });
      }
    }
  }

  document.addEventListener('click', handleLevel1);
  document.addEventListener('keydown', handleLevel1);
  document.addEventListener('click', handleLevel2);
  document.addEventListener('keydown', handleLevel2);
  document.addEventListener('click', handleLevel3);
  document.addEventListener('keydown', handleLevel3);
});

/* zaskrtavani kategorii checkbox */
document.addEventListener('DOMContentLoaded', function () {
  //document.addEventListener('click', function (e) {
  function handleCheckbox(e) {
    if ((e.type === 'keydown' && e.key !== 'Enter') && (e.type === 'keydown' && e.code !== 'Space')) return;
    if (e.target.matches('.js-filter-checkbox, .js-level-none, .js-level-none span, .js-enter-level-none, .js-space-level')) {
      // pokud by nevydilo že pri stisku Enter se otevre dalsi level menu a zaroven se to oznaci, tak potom toto zapoznamkovat.
      if ((e.type === 'keydown' && e.key === 'Enter') && (e.target.matches('.js-space-level'))) return;

      const parentLi = e.target.closest('li');
      const prevInput = e.target.previousElementSibling;
      const parentData = parentLi.getAttribute('data-parent');
      const bodyTerms = document.querySelector('.js-body-terms');
      const existingDiv = document.querySelector(`.js-t-${parentData}`);
      var currentCheckbox;

      if (e.target.matches('.js-enter-level-none, .js-space-level')) {
        const checkboxEnter = e.target.firstElementChild;
        if (checkboxEnter.checked == false) {
          checkboxEnter.checked = true;
          checkboxEnter.setAttribute('aria-checked', 'true');
        } else {
          checkboxEnter.checked = false;
          checkboxEnter.setAttribute('aria-checked', 'false');
        }
        currentCheckbox = checkboxEnter;
      } else if (prevInput) {
        if (prevInput.matches('input[type="checkbox"]')) {
          if (prevInput.checked == false) {
            prevInput.checked = false;
            prevInput.setAttribute('aria-checked', 'false');
          } else {
            prevInput.checked = true;
            prevInput.setAttribute('aria-checked', 'true');
          }
          currentCheckbox = prevInput;
        }
      } else {
        currentCheckbox = e.target;
      }

      if (currentCheckbox.checked) {
        if (currentCheckbox.classList.contains('js-checkbox-level_4')) {
          const parentDataCheckBox = currentCheckbox.getAttribute('data-parent');

          // Odškrtnutí v column-3
          const checkbox3 = document.querySelector('.js-top-block-column-3 input[data-term-slug="' + parentDataCheckBox + '"]');
          if (checkbox3) {
            checkbox3.checked = false;
            checkbox3.setAttribute('aria-checked', 'false');
            const parent3Data = checkbox3.getAttribute('data-parent');
            const termId3 = checkbox3.getAttribute('data-term-id');

            // Změna stavu štítku pro checkbox3
            const tag3 = document.querySelector(`span[data-filter-term-id="${termId3}"]`);
            if (tag3) {
              tag3.setAttribute('data-filter-keyword-status', 'false');
              tag3.closest('li').classList.add('d-none');
            }

            // Odškrtnutí v column-2
            const checkbox2 = document.querySelector('.js-top-block-column-2 input[data-term-slug="' + parent3Data + '"]');
            if (checkbox2) {
              checkbox2.checked = false;
              checkbox2.setAttribute('aria-checked', 'false');
              const termId2 = checkbox2.getAttribute('data-term-id');

              // Změna stavu štítku pro checkbox2
              const tag2 = document.querySelector(`span[data-filter-term-id="${termId2}"]`);
              if (tag2) {
                tag2.setAttribute('data-filter-keyword-status', 'false');
                tag2.closest('li').classList.add('d-none');
              }
            }
          }
        }

        if (currentCheckbox.classList.contains('js-checkbox-level_3')) {
          const parentDataCheckBox = currentCheckbox.getAttribute('data-parent');

          // Odškrtnutí v column-2
          const checkbox2 = document.querySelector('.js-top-block-column-2 input[data-term-slug="' + parentDataCheckBox + '"]');
          if (checkbox2) {
            checkbox2.checked = false;
            checkbox2.setAttribute('aria-checked', 'false');
            const termId2 = checkbox2.getAttribute('data-term-id');

            // Změna stavu štítku pro checkbox2
            const tag2 = document.querySelector(`span[data-filter-term-id="${termId2}"]`);
            if (tag2) {
              tag2.setAttribute('data-filter-keyword-status', 'false');
              tag2.closest('li').classList.add('d-none');
            }
          }
        }

        parentLi.classList.add('active');
        currentCheckbox.setAttribute('aria-checked', 'true');
        const termId = currentCheckbox.getAttribute('data-term-id');
        const existingTag = document.querySelector(`span[data-filter-term-id="${termId}"]`);

        if (existingTag) {
          // Upravit existující štítek
          existingTag.setAttribute('data-filter-term-status', 'true');
          existingTag.closest('li').classList.remove('d-none');

          // Odstranit d-none z nadřazených prvků
          const parentDiv = document.querySelector(`.js-t-${parentData}`);
          if (parentDiv) {
            parentDiv.classList.remove('d-none');
            parentDiv.querySelectorAll('.js-terms').forEach(el => el.classList.remove('d-none'));
          }
          // Najít a aktualizovat nadřazený div kategorie
          const categoryDiv = existingTag.closest('[class^="js-t-"]');
          if (categoryDiv) {
            categoryDiv.classList.remove('d-none');
            categoryDiv.querySelectorAll('.js-terms').forEach(el => {
              el.classList.remove('d-none');
            });
          }

          document.querySelector('.js-terms-none')?.classList.add('d-none');
        } else {
          // Původní kód pro přidání štítku
          const parentLi = document.querySelector(`.js-tbc-1-filter li#${parentData}`);
          const categoryName = parentLi ? parentLi.getAttribute('data-name') : '';

          if (!existingDiv && bodyTerms) {
            const newContent = `
              <div class="js-t-${parentData}">
                <h2 class="tbc-1-subtitle js-terms" tabindex="0" aria-label="${categoryName}">
                  ${categoryName}
                </h2>
                <div class="filter--result-keywords-revert js-terms">
                  <ul class="links">
                  </ul>
                </div>
              </div>
            `;
            bodyTerms.insertAdjacentHTML('beforeend', newContent);
          }

          const termSlug = currentCheckbox.getAttribute('data-term-slug');
          const termId = currentCheckbox.getAttribute('data-term-id');

          var termName = "";
          if (currentCheckbox.matches('.js-level-none') == true) {
            termName = currentCheckbox.getAttribute('data-name');
          } else {
            termName = currentCheckbox.nextElementSibling.getAttribute('data-name');
          }

          const linksList = (existingDiv || bodyTerms.querySelector(`.js-t-${parentData}`))
            .querySelector('.links');

          const newTag = `
            <li tabindex="0" aria-label="${termName}">
              <span data-filter-terms-main="t-${parentData}"
                    data-filter-term="${termSlug}"
                    data-filter-term-status="true"
                    data-filter-term-id="${termId}"
                    data-filter-term-position="0">${termName}
                <span class="icon"></span>
              </span>
            </li>
          `;

          linksList.insertAdjacentHTML('beforeend', newTag);
          document.querySelector('.js-terms-none')?.classList.add('d-none');
        }
      } else {
        if (parentLi.classList.contains('active')) {
          parentLi.classList.remove('active');
          currentCheckbox.setAttribute('aria-checked', 'false')
        }
        // Kód pro odebrání štítku
        //const termSlug = currentCheckbox.getAttribute('data-term-slug');
        const termId = currentCheckbox.getAttribute('data-term-id');
        const tagToRemove = document.querySelector(`span[data-filter-term-id="${termId}"]`);

        if (tagToRemove) {
          //tagToRemove.closest('li').remove();
          tagToRemove.setAttribute('data-filter-term-status', 'false');
          tagToRemove.closest('li').classList.add('d-none');

          // Kontrola zbývajících štítků v kategorii
          const categoryDiv = document.querySelector(`.js-t-${parentData}`);
          const remainingCategoryTags = categoryDiv?.querySelector('.links').children.length;

          if (remainingCategoryTags === 0) {
            categoryDiv?.remove();
          }

          // Kontrola celkového počtu štítků
          const remainingTags = document.querySelectorAll('.js-body-terms .links li');
          if (remainingTags.length === 0) {
            document.querySelector('.js-terms-none')?.classList.remove('d-none');
          }
        }
      }

      const visibleCategories = document.querySelectorAll('.js-body-terms .js-terms li:not(.d-none)');
      const keywordClearAll = document.querySelector('.js-terms-eraser');
      const keywordVisibleNone = document.querySelector('.js-terms-none');
      const keywordHidden = document.querySelectorAll('.tbc-1-subtitle.js-terms');

      if (visibleCategories.length == 0) {
        keywordHidden.forEach(div => {
          div.classList.add('d-none');
        });
        keywordVisibleNone.classList.remove('d-none');
        keywordClearAll.classList.add('d-none');
      } else if (visibleCategories.length > 1) {
        keywordClearAll?.classList.remove('d-none');
      } else {
        keywordClearAll?.classList.add('d-none');
      }
    }
    //});
  }

  document.addEventListener('click', handleCheckbox);
  document.addEventListener('keydown', handleCheckbox);
});

// script resici vyhledavani filtru dle slova
let searchKeyupTimeout;
document.addEventListener('DOMContentLoaded', function () {
  const jsonData = JSON.parse(localStorage.getItem('allFilterSearchTerms'));
  const cross = document.querySelector('.js-search-close');
  const popup = document.querySelector('.js-sb__popup');
  const bodyTerms = document.querySelector('.js-body-terms');

  document.body.addEventListener('keyup', function (e) {
    if (!e.target.matches('.js-filter-search-terms input')) return;

    clearTimeout(searchKeyupTimeout);
    const input = e.target;

    if (input.value.length >= 3) {
      searchKeyupTimeout = setTimeout(function () {
        document.querySelectorAll('.js-sb__categories .category-row').forEach(el => el.remove());

        const query = input.value.trim().toLowerCase();
        const highlightText = (text, query) => {
          const regex = new RegExp(`(${query})`, 'gi');
          return text.replace(regex, '<strong>$1</strong>');
        };

        function searchTerms(terms, parentPath = []) {
          let found = [];
          terms.forEach(term => {
            const currentPath = [...parentPath, { name: term.name, id: term.id }];

            if (term.name.toLowerCase().includes(query)) {
              found.push({
                ...term,
                path: currentPath
              });
            }

            if (term.children && term.children.length > 0) {
              found = found.concat(searchTerms(term.children, currentPath));
            }
          });
          return found;
        }

        let matchingCategories = [];

        jsonData.forEach(function (taxonomy) {
          var matches = searchTerms(taxonomy.terms);
          if (matches.length > 0) {
            matchingCategories.push({
              taxonomy: taxonomy.name,
              icon: taxonomy.taxonomy,
              matches: matches
            });
          }
        });

        let allMatches = [];
        matchingCategories.forEach(cat => {
          allMatches = allMatches.concat(cat.matches);
        });
        let postItemsHtml = '';
        if (allMatches.length > 0) {
          postItemsHtml = allMatches.map(item =>
            `<li><a href="#" role="link" data-term-id="${item.id}">${item.name}</a></li>`
          ).join('');
        } else {
          postItemsHtml = '<li>Nebyly nalezeny žádné výsledky</li>';
        }
        document.querySelector('.whisper-popup__content ul').innerHTML = postItemsHtml;

        let totalMatches = 0;
        matchingCategories.forEach(category => {
          totalMatches += category.matches.length;
        });

        let countItems = 0;
        let categoriesHtml = matchingCategories.map(cat => {
          const termsHtml = cat.matches.map(item => {
            const pathWithoutCurrent = item.path.slice(0, -1);
            const pathLinks = pathWithoutCurrent.map((pathItem, index) =>
              `${index > 0 ? ' / ' : ''}<span>${pathItem.name}</span>`
            ).join('');

            const currentItem = item.path[item.path.length - 1];
            countItems = countItems + 1;

            return `
                    <div class="category-item js-sb__select ${totalMatches === countItems ? `lastitem` : ``}" role="link" tabindex="0" data-parent="${cat.icon}" data-parent-name="${cat.taxonomy}" data-term-id="${currentItem.id}" data-term-name="${currentItem.name}" data-term-slug="${currentItem.slug}" arial-label="Vybrat/Select ${currentItem.name}">
                      <span class="path-line">
                        <span class="mainTitle">${cat.taxonomy}</span>
                        <span class="NextLevelTitle">${pathLinks.length > 0 ? `&nbsp;/ ${pathLinks}` : pathLinks}</span>
                      </span>
                      <span class="found-item">
                          ${highlightText(currentItem.name, query)}
                      </span>
                    </div>`;
          }).join('');

          return `
                <div class="category-row">
                    ${termsHtml}
                </div>
            `;
        }).join('');

        document.querySelector('.js-sb__categories').innerHTML = categoriesHtml;
        const totalCount = allMatches.length;
        document.querySelector('.whisper-popup__counter button span').textContent = totalCount;

        cross.classList.remove('d-none');

        if (popup) {
          popup.classList.add('open');
        }
      }, 400);
    }
  });

  // vyber z vyhledaných polozek
  function handleSelect(e) {
    if (e.type === 'keydown' && e.key !== 'Enter') return;

    if ((e.target.matches('.js-sb__select')) || (e.target.matches('.js-sb__select span')) || (e.target.matches('.js-sb__select strong'))) {
      var aSelect = e.target;

      if ((e.target.matches('.js-sb__select span')) || (e.target.matches('.js-sb__select strong'))) {
        aSelect = e.target.closest('div');
      } else {
        aSelect = e.target;
      }

      const parentData = aSelect.getAttribute('data-parent');
      const categoryName = aSelect.getAttribute('data-parent-name');
      const termId = aSelect.getAttribute('data-term-id');
      const termName = aSelect.getAttribute('data-term-name');
      const termSlug = aSelect.getAttribute('data-term-slug');
      const existingTag = document.querySelector(`span[data-filter-term-id="${termId}"]`);

      if (existingTag) {
        existingTag.setAttribute('data-filter-term-status', 'true');
        existingTag.closest('li').classList.remove('d-none');

        const parentDiv = document.querySelector(`.js-t-${parentData}`);
        if (parentDiv) {
          parentDiv.classList.remove('d-none');
          parentDiv.querySelectorAll('.js-terms').forEach(el => el.classList.remove('d-none'));
        }
        const categoryDiv = existingTag.closest('[class^="js-t-"]');
        if (categoryDiv) {
          categoryDiv.classList.remove('d-none');
          categoryDiv.querySelectorAll('.js-terms').forEach(el => {
            el.classList.remove('d-none');
          });
        }

        document.querySelector('.js-terms-none')?.classList.add('d-none');
      } else {
        const existingDiv = document.querySelector(`.js-t-${parentData}`);

        if (!existingDiv && bodyTerms) {
          const newContent = `
                <div class="js-t-${parentData}">
                    <h2 class="tbc-1-subtitle js-terms" tabindex="0" aria-label="${categoryName}">
                        ${categoryName}
                    </h2>
                    <div class="filter--result-keywords-revert js-terms">
                        <ul class="links">
                        </ul>
                    </div>
                </div>
                `;
          bodyTerms.insertAdjacentHTML('beforeend', newContent);
        }

        const linksList = (existingDiv || bodyTerms.querySelector(`.js-t-${parentData}`)).querySelector('.links');
        const newTag = `
                <li tabindex="0" aria-label="${termName}">
                    <span data-filter-terms-main="t-${parentData}"
                        data-filter-term="${termSlug}"
                        data-filter-term-status="true"
                        data-filter-term-id="${termId}"
                        data-filter-term-position="0">${termName}
                    <span class="icon"></span>
                    </span>
                </li>
            `;

        linksList.insertAdjacentHTML('beforeend', newTag);
        document.querySelector('.js-terms-none')?.classList.add('d-none');
      }

      const cross = document.querySelector('.js-search-close');
      const searchInputBox = document.querySelector('.js-filter-search-terms');
      const searchOverlay = document.querySelector('.js-filter-search-overlay');
      const popup = document.querySelector('.js-sb__popup');
      const inputSearch = document.querySelector('.js-filter-search-terms input');
      const nextItem = document.querySelector('.js-filter-info');

      e.preventDefault();
      cross.classList.add('d-none');
      if (popup) {
        popup.classList.remove('open');
      }
      inputSearch.value = '';
      searchInputBox.classList.add('d-none');
      searchOverlay.classList.add('d-none');
      nextItem.focus();
    }
  }

  document.body.addEventListener('click', handleSelect);
  document.body.addEventListener('keydown', handleSelect);

});

// ovladani krizku pri hledání
document.addEventListener('DOMContentLoaded', function () {
  const cross = document.querySelector('.js-search-close');
  const searchInputBox = document.querySelector('.js-filter-search-terms');
  const searchOverlay = document.querySelector('.js-filter-search-overlay');
  const popup = document.querySelector('.js-sb__popup');
  const inputSearch = document.querySelector('.js-filter-search-terms input');
  const nextItem = document.querySelector('.js-filter-info');

  function handleCross(e) {
    e.preventDefault();
    cross.classList.add('d-none');
    if (popup) {
      popup.classList.remove('open');
    }
    inputSearch.value = '';
    searchInputBox.classList.add('d-none');
    searchOverlay.classList.add('d-none');
    nextItem.focus();
  }

  cross.addEventListener('click', handleCross);
  cross.addEventListener('keydown', function (e) {
    if (e.key === 'Enter') {
      handleCross(e);
    }
  });
});

// ovladani vyhledávacího políčka
document.addEventListener('DOMContentLoaded', function () {
  const searchInputFalse = document.querySelector('.js-filter-search-terms-empty input');
  const searchFalse = document.querySelector('.js-filter-search-terms-empty');
  const searchInputBox = document.querySelector('.js-filter-search-terms');
  const searchOverlay = document.querySelector('.js-filter-search-overlay');
  const searchInput = document.querySelector('.js-filter-search-terms input');
  const cross = document.querySelector('.js-search-close');
  const clvl_2 = document.querySelector('.js-top-block-column-2');
  const clvl_3 = document.querySelector('.js-top-block-column-3');
  const clvl_4 = document.querySelector('.js-top-block-column-4');
  const buttonBack = document.querySelector('.js-button-back');

  // řešeni tab v ramci vyhledavacího policka abych z nej neuskocil jinam a mohl pomoci klavesnice se zde pohybovat
  function handleSearchTab() {
    const searchInput = document.querySelector('.js-filter-search-terms input');
    const cross = document.querySelector('.js-search-close');
    const popup = document.querySelector('.js-sb__popup');
    let lastSelectFocused = false;
    const searchOverlay = document.querySelector('.js-filter-search-overlay');
    const searchInputBox = document.querySelector('.js-filter-search-terms');

    if (searchOverlay && !searchOverlay.classList.contains('d-none') && searchInputBox && !searchInputBox.classList.contains('d-none')) {

      searchInput.addEventListener('keydown', function (e) {
        if (e.key === 'Tab' && !e.shiftKey) {
          e.preventDefault();
          cross.focus();
        }
      });

      cross.addEventListener('keydown', function (e) {
        if (e.key === 'Tab') {
          e.preventDefault();
          if (e.shiftKey) {
            searchInput.focus();
          } else {
            if (popup && popup.classList.contains('open')) {
              const firstResult = document.querySelector('.js-sb__select');
              if (firstResult) {
                firstResult.focus();
                lastSelectFocused = false;
              } else {
                searchInput.focus();
              }
            } else {
              searchInput.focus();
            }
          }
        }
      });

      // reseni aby kdyz se dostanu na posledni polozku preskocilo to zpet na polickohledaci
      document.addEventListener('keydown', function (e) {
        if (e.target.matches('.js-sb__select')) {
          if (e.key === 'Tab' && !e.shiftKey) {
            if (e.target.matches('.js-sb__select.lastitem')) {
              lastSelectFocused = true;
            } else {
              lastSelectFocused = false;
            }
          } else if (e.shiftKey && e.key === 'Tab') {
            e.preventDefault();
            cross.focus();
          }
          if (lastSelectFocused === true) {
            lastSelectFocused = false;
            e.preventDefault();
            searchInput.focus();
          }
        }
      });

    }
  }

  function handleSearchInput(e) {
    e.preventDefault();

    const rect = searchFalse.getBoundingClientRect();
    const position = {
      top: 80, //top: rect.top + window.scrollY,
      right: window.innerWidth - rect.right,
      bottom: rect.bottom + window.scrollY,
      left: rect.left
    };

    clvl_2.classList.add('d-none');
    clvl_3.classList.add('d-none');
    clvl_4.classList.add('d-none');
    buttonBack.classList.add('d-none');
    buttonBack.setAttribute('data-level', '1');
    document.querySelectorAll('.js-tbc-1-filter li, .js-tbc-2-filter li, .js-tbc-3-filter li, .js-tbc-4-filter li').forEach(li => li.classList.remove('toggle'));

    searchOverlay.classList.remove('d-none');
    searchInputBox.style.top = `${position.top}px`;
    searchInputBox.style.left = `${position.left}px`;
    searchInputBox.style.position = `fixed`;
    searchInputBox.style.zIndex = '1000';
    searchInputBox.classList.remove('d-none');
    cross.classList.remove('d-none');
    searchInput.focus();
    handleSearchTab();
  }

  searchInputFalse.addEventListener('click', handleSearchInput);
  searchInputFalse.addEventListener('focus', handleSearchInput);
});

// řesi že pokud na tlacitku hledej dam tab skoci to na zobrazit kategorie filtru
document.addEventListener('DOMContentLoaded', function () {
  const searchBtn = document.querySelector('.js-search-btn');
  const filterTermsButton = document.querySelector('.js-filter-terms-button');

  if (searchBtn && filterTermsButton) {
    searchBtn.addEventListener('keydown', function (e) {
      if (e.key === 'Tab' && !e.shiftKey) {
        e.preventDefault();
        filterTermsButton.focus();
      }
    });
  }
});

// pres ajax zjisti pocet zaznamu ktere odpovidaji hladacim parametrum
document.addEventListener('DOMContentLoaded', function () {
  function updateFilterResults() {
    const activeKeywordValues = Array.from(
      document.querySelectorAll('span[data-filter-keyword][data-filter-keyword-status="true"]')
    ).map(span => span.getAttribute('data-filter-keyword'));
    const queryString = '&q=' + activeKeywordValues.join('+');

    // Sestavit query pro kategorie
    const activeTermValues = Array.from(
      document.querySelectorAll('span[data-filter-term][data-filter-term-status="true"]')
    );
    const groupedTerms = activeTermValues.reduce((acc, term) => {
      const main = term.getAttribute('data-filter-terms-main');
      if (!acc[main]) acc[main] = [];
      acc[main].push(term.getAttribute('data-filter-term-id'));
      return acc;
    }, {});
    let queryStringTerms = '';
    Object.entries(groupedTerms).forEach(([main, ids]) => {
      ids.forEach((id, index) => {
        queryStringTerms += `&${main}[${index}]=${id}`;
      });
    });

    const baseUrl = window.location.href.split('?')[0];
    const newUrl = baseUrl + '?ajax=1&whisperer=1' + queryString + queryStringTerms;
    const textInfo = document.querySelector('.js-filter-info');
    const textWarning = document.querySelector('.js-filter-warning');

    searchKeyupTimeout = setTimeout(function () {
      $.ajax({
        type: 'GET',
        url: newUrl,
        success: function (response) {
          if (response.total_count === 0) {
            textWarning.classList.remove('d-none');
            textInfo.classList.add('d-none');
            if (textWarning) {
              const icon = '<div class="icon-info-warning"><i class="fa-regular fa-triangle-exclamation"></i></div>';
              if (activeTermValues.length === 0 && activeKeywordValues.length > 0) {
                if (LanguageManager.getLanguage() === 'en') {
                  textWarning.innerHTML = icon + 'The selected combination of keywords cannot display filter categories because no relevant content is available for them. Try adjusting your selection by removing some keywords.';
                }
                if (LanguageManager.getLanguage() === 'cs') {
                  textWarning.innerHTML = icon + 'Pro zvolenou kombinaci klíčových slov nelze zobrazit kategorie filtru, protože pro ně nejsou dostupné žádné relevantní obsahy. Zkuste upravit výběr odstraněním některých klíčových slov.';
                }
              }
              if (activeTermValues.length > 0 && activeKeywordValues.length > 0) {
                if (LanguageManager.getLanguage() === 'en') {
                  textWarning.innerHTML = icon + 'The selected combination of keywords and filters cannot display filter categories because no relevant content is available. Try adjusting your selection by removing some keywords or deleting filtered categories.';
                }
                if (LanguageManager.getLanguage() === 'cs') {
                  textWarning.innerHTML = icon + 'Pro zvolenou kombinaci klíčových slov a filtrů nelze zobrazit kategorie filtru, protože pro ně nejsou dostupné žádné relevantní obsahy. Zkuste upravit výběr odstraněním některých klíčových slov nebo smazáním filtrovaných kategorií.';
                }
              }
              if (activeTermValues.length > 0 && activeKeywordValues.length === 0) {
                if (LanguageManager.getLanguage() === 'en') {
                  textWarning.innerHTML = icon + 'The selected combination of filters cannot display filter categories because no relevant content is available. Remove the filtered categories and adjust your selection.';
                }
                if (LanguageManager.getLanguage() === 'cs') {
                  textWarning.innerHTML = icon + 'Pro zvolenou kombinaci filtrů nelze zobrazit kategorie filtru, protože pro ně nejsou dostupné žádné relevantní obsahy. Smažte filtrované kategorie a upravte výběr.';
                }
              }
            }
          } else {
            textInfo.classList.remove('d-none');
            textWarning.classList.add('d-none');
            if (textInfo) {
              if (LanguageManager.getLanguage() === 'en') {
                textInfo.innerHTML = 'Found <strong>' + response.total_count + ' results</strong> based on your selection.';
              }
              if (LanguageManager.getLanguage() === 'cs') {
                textInfo.innerHTML = 'Nalezeno <strong>' + response.total_count + ' výsledků</strong> na základě vašeho výběru.';
              }
            }
          }
        },
      });
    }, 400);
  }

  // Pozorování změn v js-body-terms
  const bodyTermsObserver = new MutationObserver(updateFilterResults);
  const bodyTerms = document.querySelector('.js-body-terms');
  if (bodyTerms) {
    bodyTermsObserver.observe(bodyTerms, {
      subtree: true,
      attributes: true,
      attributeFilter: ['data-filter-term-status'],
      childList: true
    });
  }

  // Pozorování změn v js-keywords
  const keywordsObserver = new MutationObserver(updateFilterResults);
  const keywords = document.querySelector('.js-keywords');
  if (keywords) {
    keywordsObserver.observe(keywords, {
      subtree: true,
      attributes: true,
      attributeFilter: ['data-filter-keyword-status'],
      childList: true
    });
  }

});
