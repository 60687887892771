$(document).ready(function () {

  let userOptionForm = $('#userOption');
  let modalLoginForm = $('#modalLoginForm');
  let recoverForm = $('#recoverForm');
  let resetPasswordForm = $('#resetPasswordForm');
  let registrationForm = $('#registrationForm');
  let newsletterForm = $('#newsletterSubscribeForm');
  let nlwSubscribeForm = $('#nlwSubscribeForm');

  jQuery.validator.addMethod(
    'regex',
    function (value, element, regexp) {
      if (regexp.constructor != RegExp)
        regexp = new RegExp(regexp);
      else if (regexp.global)
        regexp.lastIndex = 0;
      return this.optional(element) || regexp.test(value);
    }, "erreur expression reguliere"
  );

  // Formulář pro nastavení usera
  if (userOptionForm.length) {
    userOptionForm.validate({
      focusInvalid: true,
      rules: {
        password: {
          minlength: 8,
          regex: '((?=.*[0-9])(?=.*[A-Z]))'
        },
        confirmpassword: {
          equalTo: '#password'
        }
      },
      messages: {
        password: {
          required: 'Toto pole je povinné',
          regex: 'Heslo musí obsahovat číslici a velké písmeno',
          minlength: 'Heslo musí obsahovat minimálně 8 znaků'
        },
        confirmpassword: {
          required: 'Toto pole je povinné',
          equalTo: 'Hesla se musí shodovat'
        }
      }
    })
  }
  $('body').on('click', '.user-save-btn', function (e) {
    if ($(this).closest('form').attr('id') === 'userOption') {
      if (userOptionForm.valid()) {
        e.preventDefault();
        let form = $(this).closest('form');
        form.find('button.user-save-btn').addClass('is-loading');

        $.ajax({
          url: form.attr('action'),
          type: 'post',
          dataType: 'json',
          data: form.serialize(),
          success: function (data) {
            form.find('button.user-save-btn').removeClass('is-loading');

            if (data.status === 'ok') {
              form[0].reset();
              $('#userConfirmSave').addClass('active').closest('.modal-wrapper').addClass('open');
            } else {
              alert('Ups, něco se porouchalo');
            }
          }
        });
        $('#userConfirmSave').addClass('active').closest('.modal-wrapper').addClass('open');
        $('#userConfirmSave').find('.close-btn').focus();

      } else {
        userOptionForm.validate().focusInvalid();

      }
    } else {
      e.preventDefault();

      let form = $(this).closest('form');
      form.find('button.user-save-btn').addClass('is-loading');

      $.ajax({
        url: form.attr('action'),
        type: 'post',
        dataType: 'json',
        data: form.serialize(),
        success: function (data) {
          form.find('button.user-save-btn').removeClass('is-loading');

          if (data.status === 'ok') {
            $('#userConfirmSave').addClass('active').closest('.modal-wrapper').addClass('open');
          } else {
            alert('Ups, něco se porouchalo');
          }
        }
      });
    }
  });

  $('body').on('keyup', '#userOption input', function () {
    if (!$(this).val()) {
      $(this).removeClass('valid').attr('aria-invalid', '')
    }
  });

  // Modální okno pro přihlášení
  if (modalLoginForm.length) {
    modalLoginForm.validate({
      focusInvalid: true,
      messages: {
        login_email: {
          required: 'Toto pole je povinné',
          email: 'Zadejte emailovou adresu'
        },
        login_password: {
          required: 'Toto pole je povinné'
        }
      }
    })
  }

  $('body').on('click', '#modalLogin .login-btn', function (e) {
    if (modalLoginForm.valid()) {
      e.preventDefault();

      let form = $(this).closest('form');
      form.find('button.login-btn').addClass('is-loading');

      $.ajax({
        url: form.attr('action'),
        type: 'post',
        dataType: 'json',
        data: form.serialize(),
        success: function (data) {
          form.find('button.login-btn').removeClass('is-loading');

          if (data.status === 'ok') {
            if (data.isAdmin) {
              window.location.href = '/wp-admin';
            }

            window.location.href = '/';
          } else {
            $('.notification-area .warning').show();
          }
        },
        error: function () {
          let newForm = $('<form style="display: none;" action="/wp-login.php" method="POST">' +
            '<input type="text" name="log" value="' + form.find('input[name="login_email"]').val() + '" />' +
            '<input type="password" name="pwd" value="' + form.find('input[name="login_password"]').val() + '" />' +
            '</form>');

          newForm.appendTo('body').submit();
        }
      });
    } else {
      modalLoginForm.validate().focusInvalid();
    }
  });

  $('body').on('click', '#modalLogin .recover a', function () {
    $('#modalLogin').removeClass('active');
    $('#recoverModal').addClass('active').find('input').filter(':first').focus();
  });

  $('body').on('click', '#modalLogin .no-account', function () {
    $('#modalLogin').removeClass('active');
    $('#modalRegister').addClass('active').find('input').filter(':first').focus();
  });

  // Modální okno pro obnovení hesla
  if (recoverForm.length) {
    recoverForm.validate({
      focusInvalid: true,
      messages: {
        recover_email: {
          required: 'Toto pole je povinné',
          email: 'Zadejte emailovou adresu'
        }
      }
    });
  }

  $('body').on('click', '#recoverForm .recover-btn', function (e) {
    if (recoverForm.valid()) {
      e.preventDefault();

      let form = $(this).closest('form');
      form.find('button.recover-btn').addClass('is-loading');

      $.ajax({
        url: form.attr('action'),
        type: 'post',
        dataType: 'json',
        data: form.serialize(),
        success: function (data) {
          form.find('button.recover-btn').removeClass('is-loading');

          if (data.status === 'ok') {
            $('.modal-wrapper').addClass('open');
            $('#recoverModal').removeClass('active');
            $('#recoverConfirmModal').addClass('active').find('.close-btn').focus();

          } else {
            $('#recoverModal').removeClass('active');
            $('#newsletter-404').addClass('active').closest('.modal-wrapper').addClass('open');
            form[0].reset();
          }
        }
      });
    } else {
      recoverForm.validate().focusInvalid();
    }
  });

  // Modální okno pro registraci
  if (registrationForm.length) {
    registrationForm.validate({
      focusInvalid: true,
      rules: {
        registration_password: {
          minlength: 8,
          regex: '((?=.*[0-9])(?=.*[A-Z]))'
        },
        registration_confirm: {
          equalTo: '#registerPassword'
        }
      },
      messages: {
        registration_email: {
          required: 'Toto pole je povinné',
          email: 'Zadejte emailovou adresu'
        },
        registration_password: {
          required: 'Toto pole je povinné',
          regex: 'Heslo musí obsahovat číslici a velké písmeno',
          minlength: 'Heslo musí obsahovat minimálně 8 znaků'
        },
        registration_confirm: {
          required: 'Toto pole je povinné',
          equalTo: 'Hesla se musí shodovat'
        }
      }
    })
  }

  $('body').on('click', '#modalRegister .have-account', function () {
    $('#modalRegister').removeClass('active');
    $('#modalLogin').addClass('active').find('input').filter(':first').focus();
  })

  $('body').on('submit', '#registrationForm', function (e) {
    if (registrationForm.valid()) {
      e.preventDefault();

      let form = $(this).closest('form');
      form.find('button.registration-btn').addClass('is-loading');

      $.ajax({
        url: form.attr('action'),
        type: 'post',
        dataType: 'json',
        data: form.serialize(),
        success: function (data) {
          form.find('button.registration-btn').removeClass('is-loading');

          if (data.status === 'ok') {
            $('#modalRegister').removeClass('active');
            $('#modalRegisterConfirm').addClass('active').find('.close-btn').focus();
          } else if (data.status === 'recaptcha-fail') {
            $('#modalRegister').removeClass('active');
            $('#modalRegisterRecaptchaFail').addClass('active').find('.close-btn').focus();
          } else {
            $('#modalRegister').removeClass('active');
            $('#modalRegisterFail').addClass('active').find('.close-btn').focus();
          }
        }
      });
    } else {
      registrationForm.validate().focusInvalid();
    }
  });

  $('body').on('click', '#modalRegister .close-btn, #modalRegisterConfirm .close-btn, #modalRegisterFail .close-btn', function () {
    window.location.href = "/";
  })

  // Modální okno pro registraci
  if (resetPasswordForm.length) {
    resetPasswordForm.validate({
      focusInvalid: true,
      rules: {
        reset_password: {
          minlength: 8,
          regex: '((?=.*[0-9])(?=.*[A-Z]))'
        },
        reset_confirm: {
          equalTo: '#resetPassword'
        }
      },
      messages: {
        reset_password: {
          required: 'Toto pole je povinné',
          regex: 'Heslo musí obsahovat číslici a velké písmeno',
          minlength: 'Heslo musí obsahovat minimálně 8 znaků'
        },
        reset_confirm: {
          required: 'Toto pole je povinné',
          equalTo: 'Hesla se musí shodovat'
        }
      }
    })
  }

  $('body').on('click', '#resetPasswordForm .recover-btn', function (e) {
    if (resetPasswordForm.valid()) {
      e.preventDefault();

      let form = $(this).closest('form');
      form.find('button.recover-btn').addClass('is-loading');

      $.ajax({
        url: form.attr('action'),
        type: 'post',
        dataType: 'json',
        data: form.serialize(),
      }).always(function () {
        window.location.href = '/';
      });
    } else {
      resetPasswordForm.validate().focusInvalid();
    }
  });

  // Newsletter Subscribe
  if (newsletterForm.length) {
    newsletterForm.validate({
      focusInvalid: true,
      messages: {
        newsletter_email: {
          required: 'Toto pole je povinné',
          email: 'Zadejte emailovou adresu'
        },
      },
      invalidHandler: function (event, validator) {
        if (validator.errorMap.newsletter_email) {
          newsletterForm.parent(".input-wrapper").addClass("error");
        }
      },
      success: function () {
        newsletterForm.parent(".input-wrapper").removeClass("error");
      }
    });

    newsletterForm.bind("invalid-form.validate", function () {
      $('.modal-wrapper').addClass('open');
      $('#newsletter-warning').addClass('active').closest('.modal-wrapper').addClass('open');
    });

    $('#newsletter_email').on('blur keyup', function() {
        if (!$(this).valid()) {
            newsletterForm.parent(".input-wrapper").addClass("error");
        } else {
            newsletterForm.parent(".input-wrapper").removeClass("error");
        }
    });
  }

  $('.user-calculator-results .result-delete').on('click', function (e) {
    e.preventDefault();

    $('#calculatorDelete').addClass('active').closest('.modal-wrapper').addClass('open');
    $('#calculatorDelete').find('.confirm-remove').data('guid', $(this).data('guid'));
    $('#calculatorDelete').find('.close-btn').focus();
  });

  $('#calculatorDelete .confirm-remove').on('click', function (e) {
    e.preventDefault();

    let guid = $(this).data('guid');
    let item = $('.user-calculator-results .result-delete[data-guid="' + guid + '"]');

    let button = $(this);
    button.addClass('is-loading');

    $.ajax({
      url: '/wp-admin/admin-post.php',
      type: 'post',
      dataType: 'json',
      data: {
        action: 'exchange-rate-risk-calculator-delete',
        guid: item.data('guid'),
      },
      success: function (data) {
        button.removeClass('is-loading');

        if (data.success) {
          $('#calculatorDelete').removeClass('active').closest('.modal-wrapper').removeClass('open');

          item.closest('.calculator-result-wrapper').remove();
        } else {
          alert('Ups, něco se porouchalo.');
        }
      },
    });
  });

  // Newsletter widget Subscribe
  if (nlwSubscribeForm.length) {
    nlwSubscribeForm.validate({
      focusInvalid: true,
      messages: {
        nwl_email: {
          required: 'Toto pole je povinné',
          email: 'Zadejte emailovou adresu'
        },
      }
    })
  }

  $('body').on('submit', '#nlwSubscribeForm', function (e) {
    e.preventDefault();

    let form = $(this);

    if (nlwSubscribeForm.valid()) {
      $('.nlw-dynamic-content').html('<div class="nlw-response "><div class="icon"></div><div class="text">Odesílání...</div>');

      $.ajax({
        type: 'POST',
        url: form.attr('action'),
        data: form.serialize(),
        success: function (data) {
          if (data.status === 'ok') {
            window.localStorage.setItem('hideNewsletterWidget', true);

            $('.nlw-dynamic-content').html(`
              <div class="nlw-response success">
                <div class="icon"></div>
                <div class="text">
                  <strong>Žádost k odběru přijata.</strong><br>
                  Na Váš e-mail jsme zaslali žádost o potvrzení
                </div>
              </div>
            `);
          } else {
            $('.nlw-dynamic-content').html(`
              <div class="nlw-response error">
                <div class="icon"></div>
                <div class="text">
                  <strong>Odběr newsletteru</strong><br>
                  Na tuto e-mailovou adresu již je přijímán newsletter.
                </div>
              </div>
            `);
          }

          form[0].reset();
        },
        error: function () {
          $('.nlw-dynamic-content').html(`
            <div class="nlw-response error">
              <div class="icon"></div>
              <div class="text">
                <strong>Odběr newsletteru</strong><br>
                Ups, něco se pokazilo.
              </div>
            </div>
            `);
        }
      });

      e.preventDefault();
      //$('.nlw-dynamic-content').html(successTemp);
    } else {
      $('.nlw-dynamic-content').empty();
      let errorTemp = `
      <div class="nlw-response error">
        <div class="icon"></div>
        <div class="text">
          <strong>Odběr newsletteru</strong><br>
          Zadejte prosím e-mailovou adresu ve správném formátu.
        </div>
      </div>
      `

      $('.nlw-dynamic-content').append(errorTemp);
    }
  })
});






