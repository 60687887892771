//desktop - tablet
$(document).ready(function() {
    // Zjistí, kolik slajdů se má zobrazit podle třídy rodiče
    let slidesToShow = 2; // Výchozí hodnota

    if ($('.hp-video-slider-wrapper.desktop-tablet').hasClass('column-2')) {
        slidesToShow = 1;
    }

	// jen 1 slider item fix
    var count = $('.hp-video-slider-wrapper.desktop-tablet .hp-video-slider-item').length;
    if (count === 1) {
        slidesToShow = 1;
    }
    // Kontrola existence a inicializace slideru pro video
    if ($('.hp-video-slider-wrapper.desktop-tablet .hp-video-slider:not(.audio)').length) {
        //video carousel
        $('.hp-video-slider-wrapper.desktop-tablet .hp-video-slider:not(.audio)').slick({
            dots: true,
            arrows: false,
            autoplay: true,
            infinite: false,
            slidesToShow: slidesToShow,
            slidesToScroll: 1,
            autoplaySpeed: 5000,
            responsive: [{
                breakpoint: 961,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }]
        });
    }

    // Kontrola existence a inicializace slideru pro audio
    if ($('.hp-video-slider-wrapper.desktop-tablet .hp-video-slider.audio').length) {
        //audio carousel
        $('.hp-video-slider-wrapper.desktop-tablet .hp-video-slider.audio').slick({
            dots: true,
            arrows: false,
            autoplay: true,
            infinite: false,
            slidesToShow: slidesToShow,
            slidesToScroll: 1,
            autoplaySpeed: 8000,
            responsive: [{
                breakpoint: 961,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }]
        });
    }
});

//mobil
$(document).ready(function() {
    // Zjistí, kolik slajdů se má zobrazit podle třídy rodiče
    let slidesToShow = 2;

    if ($('.hp-video-slider-wrapper.mobile').hasClass('column-2')) {
        slidesToShow = 1;
    }

    // Kontrola existence a inicializace slideru pro video
    if ($('.hp-video-slider-wrapper.mobile .hp-video-slider:not(.audio)').length) {
        //video
        $('.hp-video-slider-wrapper.mobile .hp-video-slider:not(.audio)').slick({
            dots: true,
            arrows: false,
            autoplay: true,
            infinite: false,
            slidesToShow: slidesToShow,
            slidesToScroll: 1,
            autoplaySpeed: 5000,
            responsive: [{
                    breakpoint: 799,
                    settings: {
                        slidesToShow: slidesToShow,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 565,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }
            ]
        });
    }

    // Kontrola existence a inicializace slideru pro audio
    if ($('.hp-video-slider-wrapper.mobile .hp-video-slider.audio').length) {
        //audio
        $('.hp-video-slider-wrapper.mobile .hp-video-slider.audio').slick({
            dots: true,
            arrows: false,
            autoplay: true,
            infinite: false,
            slidesToShow: slidesToShow,
            slidesToScroll: 1,
            autoplaySpeed: 8000,
            responsive: [{
                    breakpoint: 799,
                    settings: {
                        slidesToShow: slidesToShow,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 565,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }
            ]
        });
    }
});


/*fix fancy-box slick.js conflict*/
$(document).ready(function() {
    // Před zavřením Fancyboxu uložíme aktuální slider a skryjeme ho
    $(document).on('beforeClose.fb', function(e, instance, slide) {
        var $trigger = slide?.opts?.$orig || instance.current.$trigger;
        var $slider = $trigger.parents('.hp-video-slider, .right-col-video-slider');

        if ($slider.length) {
            instance.$currentSlider = $slider;
            $slider.addClass('slick-hidden-fix'); // Skryjeme slider
        }
    });


    // Po zavření Fancyboxu resetujeme slider
    $(document).on('afterClose.fb', function(e, instance) {
        var $slider = instance.$currentSlider;
        if ($slider && $slider.length) {
            setTimeout(function() {
                if ($slider.hasClass('slick-initialized')) {
                    $slider.slick('refresh'); // Oprava pozice a obnovení slideru
                }
                $slider.removeClass('slick-hidden-fix'); // Vrátíme viditelnost
            }, 100);
        }
        instance.$currentSlider = null; // Vyčistíme uložený slider
    });

});