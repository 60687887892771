$(document).ready(function () {
  let queryForm = [];

  let form = $('#onlineQueryForm');

  if (form.length) {
    form.validate({
      focusInvalid: false,
      rules: {
        ico: {
          required: "#has-ico:not(:checked)",
          minlength: 8
        },
        phone: {
          minlength: 9
        }
      },
      messages: {
        type: {
          required: $('html').attr('lang') === 'en' ? 'One of the options must be selected' : 'Musí být vybrána jedna z možností'
        },
        email: {
          email: $('html').attr('lang') === 'en' ? 'Email adress must be in correct format' : 'Email musí být ve správném formátu'
        },
        ico: {
          minlength: $('html').attr('lang') === 'en' ? 'The length of ID number must be maximum 8 digits' : 'Délka IČ musí být 8 číslic'
        },
        phone: {
          minlength: $('html').attr('lang') === 'en' ? 'The phone number must contain at least 9 digits' : 'Telefon musí obsahovat minimálně 9 číslic'
        }
      },
      errorPlacement: function (error, element) {
        error.appendTo(element.closest('.input-wrapper, .radio-wrapper').find('label .error-label'));
      }
    })

    $.validator.messages.required = $('html').attr('lang') === 'en' ? 'This field is empty' : 'Povinné pole';

    $('.dragdrop').dropzone({
      url: $('.dragdrop').data('upload-url'),
      clickable: ['.dragdrop-btn', '.dragdrop'],
      previewsContainer: '.dragdrop-previews',
      previewTemplate: document.getElementById('dropzone-preview-template').innerHTML,
      addedfiles: function () {
        $('#submit-query').addClass('is-loading').prop('disabled', 'disabled');
      },
      queuecomplete: function () {
        $('#submit-query').removeClass('is-loading').prop('disabled', false);
      },
      success: function (data) {
        let response = $.parseJSON(data.xhr.response);
        let file = response.name;
        let field = $(`input#attachments`);

        if (field.length) {
          let files = field.val() ? field.val().split(';') : [];
          files.push(file);
          field.val(files.join(';'));
        }
      },
      removedfile: function (data) {
        if (data.xhr) {
          let response = $.parseJSON(data.xhr.response);
          let file = response.name;
          let field = $(`input#attachments`);

          if (field.length) {
            let files = field.val() ? field.val().split(';') : [];
            files.splice($.inArray(file, files), 1);
            field.val(files.join(';'));
          }
        }

        data.previewElement.remove();
      }
    });

    $('.dragdrop').keypress(function (event) {
      if (event.keyCode === 13) {
        $('.dragdrop').click();
      }
    });

    $('body').on('change', '#has-ico', function () {
      if ($(this).is(':checked')) {
        $('#ico').val('').prop('disabled', true).attr('aria-invalid', '').removeClass('error');
        $('#ico').closest('.input-wrapper').find('.error-label').empty();
      } else {
        $('#ico').prop('disabled', false);
      }
    })


    $('body').on('click', '.form-steps a', function (e) {
      e.preventDefault();
      let goTo = $(this).attr('data-step');
      let thisPage = $('.form-steps').find('a.active').attr('data-step');
      let nextPage = $(this).attr('data-step')

      if (thisPage == 1 && nextPage == 3) {
        return
      } else if (thisPage < nextPage) {
        if (form.valid()) {
          fillFormArr();
          goToStep(goTo);
        } else {
          form.validate().focusInvalid();
        }
      } else {
        goToStep(goTo);
      }
    })

    $('body').on('click', '.article-content.online-query .step-btn', function (e) {
      e.preventDefault();
      if (form.valid() || !$(this).hasClass('next')) {
        let goTo = $(this).attr('data-step');
        fillFormArr();
        goToStep(goTo);
        $('html, body').animate({
          scrollTop: $('.form-steps').offset().top
        }, 300);
      } else {
        form.validate().focusInvalid();
      }
    })

    $('#btn').on('click', function () {
      $("#form1").valid();
    });

    let fillFormArr = function () {
      queryForm = $('#onlineQueryForm').serializeArray()
      let type = queryForm[0].value;
      if (type == 1 || type == 3) {
        $('#companydata').hide();
        $('#execute-form option[value="personal"]').show();
      } else {
        $('#companydata').show();
        $('#execute-form option[value="personal"]').hide();
      }

      if (type == 1) {
        $('#region').hide();
        $('#contactPoint').show();
        $('#executeFormElement').removeClass('alone');
      } else if (type == 2) {
        $('#region').show();
        $('#contactPoint').hide();
        $('#executeFormElement').removeClass('alone');
      } else {
        $('#region').hide();
        $('#contactPoint').hide();
        $('#executeFormElement').addClass('alone');
      }
    }

    let goToStep = function (num) {
      $('.query-step-wrapper.step-' + num).addClass('active').siblings().removeClass('active');
      let currentStep = $('.form-steps a.step-' + num);
      currentStep.addClass('active').removeClass('done');
      currentStep.prevAll().addClass('done').removeClass('active');
      currentStep.nextAll().removeClass('done active');
    }

    $('body').on('change', '.query-types input[type=radio]', function () {
      var type = $(this).closest('.query-type');
      var id = $(this).attr('id');
      var stepOneForm = $('.query__input-wrapper.step1');

      id == 1 || id == 3 ? $('.query__input-wrapper.step1').find('.topic').hide() : $('.query__input-wrapper.step1').find('.topic').show();

      $(this).closest('.query-type').addClass('active').siblings().removeClass('active')

      $(stepOneForm).detach().appendTo(type).show();
    })
  }
});

