$(document).ready(function() {
  $('form#user-form').submit(function(e) {
    e.preventDefault();

    let form = $(this);
    form.find('button[type="submit"]').addClass('is-loading');

    $.ajax({
      url: form.attr('action'),
      type: 'post',
      dataType: 'json',
      data: form.serialize(),
      success: function (data) {
        if (data.status === 'ok') {
          form.find('button[type="submit"]').removeClass('is-loading');

          if(data.next && data.next.length) {
            $('#user-form-sent a#next-form').prop('href', data.next);
          }
          
          $('#user-form-sent').addClass('active').closest('.modal-wrapper').addClass('open');

          form[0].reset();
        } else {
          alert('Ups, něco se nepovedlo. Zkontrolujte prosím zadané údaje a odeslání opakujte.');
        }
      },
    });
  });
});
