var updatingVideos = false;

const randomId = function(length = 6) {
  return Math.random().toString(36).substring(2, length+2);
};

let loadAudioVideoWidgets = function () {
  if (updatingVideos) {
    return
  }

  updatingVideos = true;

  $(window).off('load', loadAudioVideoWidgets);

  // pokud vyberu z knihovny
  $('.gx-audio-video-widget[data-video-id], .gx-audio-video-widget[data-video-filter]').each(function () {
    var item = $(this);

    let suffix = '';
    if (item.data('video-id')) {
      suffix = 'video-id=' + item.data('video-id');
    } else {
      suffix = 'video-filter=' + item.data('video-filter');
    }

    $.ajax({
      type: 'GET',
      url: '?ajax=video&' + suffix,
      cache: false,
      //url: 'https://bi-test.geetix.com/clanky/audio-test-upevnujeme-pozici-svetove-jednicky-v-oblasti-mobilu-pro-nevidome-video/?ajax=video&' + suffix,
      success: function (response) {
        if (response.error) {
          item.remove();
        } else {
          item.data('video-id', response.video);
          let links = response.links ? response.links : [];
          let temp = buildTemplate(response.short_name ? response.short_name : response.title, response.embed, links, response.image);
          item.html('');
          if (item.data('container-title')) {
            item.append('<h3>' + item.data('container-title') + '</h3>');
          }
          item.append(temp);
        }
      },
    });
  });

  // pokud vlozim na primo
  $('.gx-audio-video-widget[data-video-direct]').each(function () {
    var item = $(this);
    let title = item.attr('data-title');
    let embed = item.data('embed') ? JSON.parse(item.attr('data-embed')) : [];
    let image = item.attr('data-image');
    let links = item.data('links') ? JSON.parse(item.attr('data-links')) : [];

    if (embed.length === 0 && links.length === 0) {
      // nic nevypise protoze neni zadan zadny odkaz
      item.remove();
    } else {
      let temp = buildTemplate(title, embed, links, image);
      item.html('');

      if (item.data('container-title')) {
        temp.append('<h3>' + item.data('container-title') + '</h3>');
      }

      item.append(temp);
    }
  });

  updatingVideos = false;
};

let buildTemplate = function (title, embed, links, image) {
  //console.log(title, embed, links, image);
  let embediframe;
  let embediframeImage;
  let cookieBar;
  let placeholderClass;
  let placeholderBox;
  let placeholderClassText = '';
  let titleTemp;
  let linkList = '';

  if (window.cookieConsent.allowedCategory('third_party')) {
    placeholderClass = '';
    cookieBar = '';

    if (title) {
      titleTemp = $(`<h4>${title}</h4>`);
      if (title.length > 70) {
        placeholderClassText = `class="fontmore70"`;
      }
      placeholderBox = `
        <div class="box"><i class="play"></i><span ${placeholderClassText}>${title}</span></div>
      `;
    } else {
      titleTemp = $(``);
    }

    if (embed.provider === 'youtube') {
      // youtube

      var idIframe = randomId(10);

      if (!image) {
        // nic
        embediframeImage = '';
      } else {
        placeholderClass = 'placeholder';
        titleTemp = $(``);

        if (title.length > 70) {
          placeholderClassText = `class="fontmore70"`;
        }
        placeholderBox = `
          <div class="box"><i class="play"></i><span ${placeholderClassText}>${title}</span></div>
        `;

        embediframeImage = (`
          <a href='#' class='js-placeholder' id='${idIframe}'>
            ${placeholderBox}
            <img src="${image}" onerror="this.style.display='none';">
          </a>
        `);
      }

      embediframe = $(`
        ${embediframeImage}
        <iframe src="${embed.src}" width="100%" height="232" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" id="youtube-${idIframe}"></iframe>
      `)
    } else if (embed.provider === 'spotify') {
      // spotify
      if (embed.url.includes('album')) {
        embed.provider = 'spotify-playlist';
        embediframe = $(`
          <iframe src="${embed.src}" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
        `)
      } else {
        embed.provider = 'spotify-single';
        embediframe = $(`
          <iframe src="${embed.src}" width="100%" height="232" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
        `)
      }
    } else if (embed.provider === 'apple') {
      // Apple Podcasts
      embediframe = $(`
        <iframe allow="autoplay *; encrypted-media *; fullscreen *" frameborder="0" height="175" style="width:100%;max-width:660px;overflow:hidden;background:transparent;" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" src="${embed.src}"></iframe>
      `)
    } else if (embed.provider === 'google') {
      // Google Podcasts nevkladat bi-3256
      /*placeholderClass = 'placeholder';
      titleTemp = '';
      if (!image) {
        embediframe = $(`
          <a href="${embed.url}" target="_blank">
            ${placeholderBox}
          </a>
        `);
      } else {
        embediframe = $(`
          <a href="${embed.url}" target="_blank">
            ${placeholderBox}
            <img src="${image}">
          </a>
        `);
      }*/
    } else if (embed.provider === 'soundcloud') {
      //  Soundcloud
      placeholderClass = 'placeholder';
      titleTemp = '';
      if (!image) {
        embediframe = $(`
          <a href="${embed.url}" target="_blank">
            ${placeholderBox}
          </a>
        `);
      } else {
        embediframe = $(`
          <a href="${embed.url}" target="_blank">
            ${placeholderBox}
            <img src="${image}" onerror="this.style.display='none';">
          </a>
        `);
      }
    } else if (embed.provider === 'vimeo') {
      //  Vimeo
      embediframe = $(`
        <iframe src="${embed.src}" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
      `);
    } else if (embed.provider === 'captivate') {
      //  Captivate
      embediframe = $(`
        <iframe style="width: 100%; height: 200px;" frameborder="no" scrolling="no" allow="clipboard-write" seamless src="${embed.src}"></iframe>
      `)
    }

  } else {
    placeholderClass = 'placeholder';

    if (title) {
      if (title.length > 70) {
        placeholderClassText = `class="fontmore70"`;
      }
      placeholderBox = `
        <div class="box"><i class="play"></i><span ${placeholderClassText}>${title}</span></div>
      `;
    } else {
      title = '';
      placeholderBox = `
        <div class="box"><i class="play"></i><span ${placeholderClassText}>${title}</span></div>
      `;
    }

    if (!image) {
      embediframe = $(`
        <a href="${embed.url}" target="_blank">
          ${placeholderBox}
        </a>
      `);
    } else {
      embediframe = $(`
        <a href="${embed.url}" target="_blank">
          ${placeholderBox}
          <img src="${image}" onerror="this.style.display='none';">
        </a>
      `);
    }
  }

  if (((window.cookieConsent.get('data') && window.cookieConsent.get('data').decline_third_party_cookies)) || (window.cookieConsent.allowedCategory('third_party'))) {
    linkList = $('<div class="links"><ul></ul></div>');
    if (Object.keys(links).length) {
      $.each(links, function (provider, detail) {
        if (provider === 'google') {
          // google nevkladat bi-3256
        } else {
          let linkRow = $(`<li><a href="${detail.url}" role="link" class="${provider}" target="_blank" aria-label="${detail.title}" title="Ikona ${detail.title}"></li>`);
          linkList.find('ul').append(linkRow)
        }
      })
    }
    if (Object.keys(embed).length) {
      let linkRow = $(`<li><div class="mob"><span>Přejít na</span><a href="${embed.url}" role="link" class="${embed.provider}" target="_blank" aria-label="${embed.provider[0].toUpperCase()}${embed.provider.slice(1)}" title="Ikona ${embed.provider[0].toUpperCase()}${embed.provider.slice(1)}"></div></li>`);
      linkList.find('ul').append(linkRow);
    }
  } else {
    cookieBar = $(`
      <div class="audio-video-cookies">
        <p>Kliknutím na náhled / tlačítko Přehrát Vás přesměrujeme na samotný audio/video obsah na webu třetí strany. Pro přehrávání audio/video obsahu přímo na stránkách BusinessInfo.cz, prosím potvrďte jednorázově souhlas s ukládáním cookies třetích stran.</p>
        <div class="btn-row">
          <button class="agree accept-third-party-cookies" role="button">Souhlasím</button>
          <button class="noask decline-third-party-cookies" role="button">Znovu se neptat</button>
        </div>
      </div>
    `);
  }

  let temp = $(`
            <div class="audio-video-content">
              <span class="titlePosition"></span>
                <div class="wrapper">
                  <div class="main-container  ${embed.provider} ${placeholderClass}">
                  </div>
                </div>
              </div>
          `);
  temp.find('.titlePosition').append(titleTemp);
  temp.find('.main-container').append(embediframe);
  temp.find('.wrapper').append(linkList);
  temp.append(cookieBar);

  return temp;
};

$(window).on('load', loadAudioVideoWidgets);

$(document).ready(function () {
  window.onAcceptCookie = function (cookie) {
    if ($.inArray('third_party', cookie.level) > -1) {
      $('.audio-video-cookies').remove();
      loadAudioVideoWidgets();
    }
  };

  $('body').on('click', '.accept-third-party-cookies', function (e) {
    e.preventDefault();

    window.cookieConsent.accept('third_party');
    loadAudioVideoWidgets();
  });

  $('body').on('click', '.decline-third-party-cookies', function (e) {
    e.preventDefault();

    window.cookieConsent.set('data', { value: { decline_third_party_cookies: true } });
    loadAudioVideoWidgets();
    //$('.audio-video-cookies').remove();
  });

  $('body').on('click', '.show-cookies-settings', function (e) {
    e.preventDefault();

    window.cookieConsent.showSettings();
  });

  $('body').on('click', '.js-placeholder', function (e) {
    e.preventDefault();
    $('#' + this.id).parent().removeClass('placeholder');
    $('#' + this.id).remove();
    var $video = $('#youtube-' + this.id);
    var src = $video.attr('src');
    if (src.indexOf('?') > -1) {
      $video.attr('src', src + '&autoplay=1');
    } else {
      $video.attr('src', src + '?autoplay=1');
    }
  });

});
