document.addEventListener("DOMContentLoaded", function () {

  window.resizeResetClassStyle = function(breakpointDown, breakpointUp, className, removeClass, removeStyle) {
    const item = document.querySelector(className);

    if (className && item) {
      if (window.innerWidth !== 0 && window.innerWidth < breakpointDown) {
        if (removeClass !== 0) {
          item.classList.remove(removeClass);
        }

        if (removeStyle !== 0) {
          item.style[removeStyle] = '';
        }
      }

      if (window.innerWidth !== 0 && window.innerWidth > breakpointUp) {
        if (removeClass !== 0) {
          item.classList.remove(removeClass);
        }

        if (removeStyle !== 0) {
          item.style[removeStyle] = '';
        }
      }
    }
  }

});
