$(document).ready(function () {
  $('.js-accordion-controller, .close-accordion').on('click',function () {
    setTimeout(() => {
      $('.wp-block-pb-accordion-item:not(.is-open) iframe').each(function () {
        $(this).attr('src', $(this).attr('src'));
      })
    }, 350);
  })

  let originalPlaceholder = $('.search__input-wrapper input').attr('placeholder');
  $('.search__input-wrapper input').fitholder({
    "(max-width: 799px)": $('html').attr('lang') === 'en'? 'What seek?' : 'Co hledáte?',
    "(min-width: 800px)": originalPlaceholder
  });

  $(window).scroll(function () {
    let scroll_top = $(window).scrollTop();
    if (window.innerWidth >= 799) {
      let menu_place = $('.sticky-menu-container').offset().top;
      if (scroll_top > menu_place) {

        $('.sticky-menu').addClass('sticky')
      }
      else {
        $('.sticky-menu').removeClass('sticky open');
        $('.sticky-menu-container').removeClass('open');
        $('.sticky-menu .nav-btn').removeClass('open');
        $('body').removeClass('sticky-bg');
      }
    } else {
      if (scroll_top == 0) {
        $('.sticky-menu').removeClass('sticky');
      }
    }
    if (window.innerWidth < 799) {
      if (scroll_top > 100) {
        $('.sticky-menu').addClass('mobileScrolled')
      } else {
        $('.sticky-menu').removeClass('mobileScrolled')
      }
    }
  })

  $(window).resize(function () {
    let scroll_top = $(window).scrollTop();
    if (window.innerWidth >= 799 && scroll_top == 0) {
      $('.sticky-menu').removeClass('open');
      $('.sticky-menu-container').removeClass('open');
      $('body').removeClass('sticky-bg');
      $('.sticky-menu .nav-btn').removeClass('open');
    }

    if (window.innerWidth >= 799 && scroll_top > 0 && $('.sticky-menu').hasClass('open')) {
      $('.sticky-menu').addClass('sticky');
    }
  });

  $('body').on('click', '.newsletter-widget .nlw-close-btn', function (e) {
    e.stopPropagation();
    e.preventDefault();

    window.localStorage.setItem('hideNewsletterWidget', true);

    $(this).closest('.newsletter-widget').hide();
  })

  $('body').on('click', `a[data-modal='#modalRegister'], a[data-modal='#modalLogin']`, function () {
    $('.sticky-menu').removeClass('open');
    $('.sticky-menu-container').removeClass('open');
    $('body').removeClass('sticky-bg');
    $('.sticky-menu .nav-btn').removeClass('open');
  })

  $('body').on('click', '.top-header__user .newsletter', function () {
    $('.sticky-menu').removeClass('open');
    $('.sticky-menu-container').removeClass('open');
    $('body').removeClass('sticky-bg');
    $('.sticky-menu .nav-btn').removeClass('open');
  })


  let urls = $('.article-content').find('a');
  urls.each(function (element) {
    if (isUrl($(this).text())) {
      $(this).addClass('break-url');
    }
  })

  function isUrl(text) {
    return text.match(/(https?:\/\/[^\s]+)/g, text) || text.match(/(www\.[^\s]+)/g, text)
  }

  if ('objectFit' in document.documentElement.style === false) {
    tkObjectFit()
  }

  function tkObjectFit() {

    $('img.objFit').each(function () {
      var imgSrc = $(this).attr('src');
      var fitType = 'cover';

      if ($(this).data('fit-type')) {
        fitType = $(this).data('fit-type');
      }
      $(this).parent().css({ 'background': 'transparent url("' + imgSrc + '") no-repeat center center/' + fitType, });
      $(this).remove();
    });
  }

  $('ol').each(function () {
    var val = 1;
    if ($(this).attr('start')) {
      val = $(this).attr('start');
    }
    val = 'list ' + (val - 1);
    $(this).css('counter-increment', val);
  });


  $('body').on('click', '.nav-btn', function () {
    $(this).toggleClass('open');
    $(this).closest('.sticky-menu').toggleClass('open');
    $(this).closest('.sticky-menu-container').toggleClass('open');
    $('body').toggleClass('sticky-bg');
  })

  var searchKeyupTimeout;
  $('body').on('keyup', '.search__input-wrapper input', function () {
    clearTimeout(searchKeyupTimeout);

    var input = $(this);
    var form = $(this).closest('form');

    if (input.val().length >= 3) {
      searchKeyupTimeout = setTimeout(function () {
        form.addClass('is-loading');

        $('.whisper-popup__categories .category-row').remove('');
        $('.whisper-popup__content ul li').remove();
        $('.whisper-popup__counter button span').html('');

        $.ajax({
          type: 'GET',
          url: form.attr('action') + '?ajax=1&whisperer=1&q=' + encodeURIComponent(input.val().trim()),
          success: function (response) {
            form.removeClass('is-loading');

            input.closest('.search__input-wrapper').find('.whisper-popup').addClass('open');

            let categoriesArr = response.categories;
            let postsArr = response.posts;

            let postItemsHtml = '';
            $.each(postsArr, function (key, item) {
              postItemsHtml += `
                <li><a href="${item.link}" role="link">${item.name}</a></li>
              `
            });
            $('.whisper-popup__content ul').html(postItemsHtml);

            let categoriesHtml = '';
            $.each(categoriesArr, function (key, item) {
              let parent = item.parent ? '<span class="category"><a href="' + item.parent.link + '" role="link">' + item.parent.name + '</a></span>' : '';

              let category = '<span class="category"><a href="' + item.link + '" role="link">' + item.name + '</a></span>'

              categoriesHtml += `
                <div class="category-row ${item.type}">
                  <span class="title">${item.taxonomy}:</span>
                  ${parent}
                  ${category}
                </div>
              `;
            });
            $('.whisper-popup__categories').html(categoriesHtml);

            $('.whisper-popup__counter button span').html(response.total_count)
          },
        });
      }, 400);
    }

  });

  $('body').on('click', function (event) {
    var $target = $(event.target);
    if (!$target.closest('.whisper-popup').length &&
      $('.whisper-popup').hasClass('open')) {
      $('.whisper-popup').removeClass('open');
    }
  });

  $('body').on('click', '.top-header__user .user-name', function (e) {
    e.preventDefault();
    e.stopPropagation();
    $('.user-nav').toggleClass('open');
  })

  $('body').on('keydown', function (event) {
    if (event.key == "Escape" && $('.user-nav').hasClass('open')) {
      $('.user-nav').removeClass('open')
    }
  });

  $('body').on('click', function (event) {
    var $target = $(event.target);
    if (!$target.closest('.user-nav').length &&
      $('.user-nav').hasClass('open')) {
      $('.user-nav').removeClass('open');
    }
  });

  $('body').on('click', '.article-detail .onpage-labels a', function (e) {
    e.stopPropagation();
    e.preventDefault();
    let label = $(this);
    let labelName = label.text();
    label.toggleClass('selected');
    label.closest('.article-content').find('.labels-wrapper ul li:contains(' + labelName + ')').toggleClass('selected');
    let count = $('.labels-wrapper ul li:contains(' + labelName + ')').length;
    label.attr('data-count', count)
  });

  $('body').on('submit', 'form#newsletterSubscribeForm', function (e) {
    e.preventDefault();
    let form = $(this);
    $.ajax({
      type: 'POST',
      url: form.attr('action'),
      data: form.serialize(),
      success: function (data) {
        $('.modal-wrapper').addClass('open');
        $('#newsletter-' + data.status).addClass('active').closest('.modal-wrapper').addClass('open');
        form[0].reset();
      },
      error: function (data) {
        alert('Ups, něco se pokazilo')
      }
    });
  });

  $('body').on('click', '.show-hint', function (e) {
    e.preventDefault();
    e.stopPropagation();
    $(this).next().toggle();
    $(this).toggleClass('open');
  });

  $('body').on('click', '.sdg-detail .more a', function (e) {
    e.stopPropagation();
    $(this).parent().css('max-height', 'initial');
    $(this).remove();
  });

  $('body').on('click', '.sdg-tabs .sdg-tabs-titles a', function (e) {
    e.preventDefault();
    e.stopPropagation();
    let tab = $(this).attr('href');
    $(this).addClass('active').siblings().removeClass('active');
    $(this).closest('.sdg-tabs').find('.sdg-tab').hide();
    $(this).closest('.sdg-tabs').find('.sdg-tab' + tab).show();
  });


    // Funkce pro přidání tříd open a active při kliknutí na .sdg-submenu-expander
    $('body').on('click', '.sdg-submenu-expander', function () {
      // Zkontroluj, jestli je šířka okna menší než 800px
      if (window.matchMedia("(max-width: 800px)").matches) {
        // Najdi všechny položky s třídou current-menu-ancestor a přidej třídy open a active
        $('.current-menu-ancestor').addClass('open active');

              // Najdi .sidebar-menu, která je po .sdg-submenu-expander
      var sidebarMenu = $(this).next('.sidebar-menu'); // Získáme .sidebar-menu, která je následující prvek
  
      // Najdi všechny li s třídou .menu-item-has-children v .sidebar-menu
      sidebarMenu.find('ul > li.menu-item-has-children').each(function () {      
        // Najdi poslední li v .sub-menu
        var lastChild = $(this).find('> .sub-menu > li').last();

        // Pokud poslední li má třídu .menu-item-has-children, přidej třídu last-bottom-border
        if (lastChild.hasClass('menu-item-has-children')) {
          lastChild.addClass('last-bottom-border');
        }
      });
      } 
      // Toggle class 'open' na parentu sdg-submenu-expander
      $(this).parent().toggleClass('open');
    });
  
    // Vytvoř MediaQueryList objekt pro sledování změny šířky okna
    var mediaQuery = window.matchMedia("(max-width: 800px)");
    // Funkce pro přidání nebo odstranění tříd podle aktuální šířky okna
    function handleMediaQueryChange(x) {
      if (!x.matches) { // Pokud je okno širší než 800px
        $('.sidebar-menu .menu-item').removeClass('open active');
        // Odebereme třídu 'open' z .sdg-submenu-expander, pokud je šířka okna > 800px
        $('.sdg-submenu-expander').parent().removeClass('open');
      }
    }
  
    // Zavolání funkce při načtení
    handleMediaQueryChange(mediaQuery);
  
    // Přidání posluchače pro změnu stavu
    mediaQuery.addEventListener("change", handleMediaQueryChange);
    

  $('body').on('click', '.sdg-submenu.db .sdg-submenu-expander', function () {
    $(this).closest('.sdg-submenu.db').toggleClass('open');
  })

  $('body').on('mouseover', '.sdg-submenu.db .has-child, .sdg-submenu.db .has-second-child', function () {
    if (window.matchMedia("(min-width: 801px)").matches) {
      $(this).closest('li').addClass('open');
    }
  })

  $('body').on('mouseout', '.sdg-submenu.db .has-child, .sdg-submenu.db .has-second-child', function () {
    if (window.matchMedia("(min-width: 801px)").matches) {
      $(this).closest('li').removeClass('open');
    }
  })



  $('body').on('click', '.sdg-submenu.db .has-child a, .sdg-submenu.db .has-second-child a', function (e) {
    e.stopPropagation();
    if (window.matchMedia("(max-width: 800px)").matches) {
      $(this).toggleClass('open');
    }
  })

  $('body').on('mouseover', '.sdg-submenu.horizontal .menu-item-has-children', function () {
    if (window.matchMedia("(min-width: 801px)").matches) {
      $(this).closest('li').addClass('open');
    }
  })

  $('body').on('mouseout', '.sdg-submenu.horizontal .menu-item-has-children', function () {
    if (window.matchMedia("(min-width: 801px)").matches) {
      $(this).closest('li').removeClass('open');
    }
  })


  $('body').on('click', '.sdg-submenu.horizontal .menu-item-has-children > span', function (e) {

    if (window.matchMedia("(max-width: 800px)").matches) {
      $(this).closest('li').toggleClass('open');
    }
  })




  $('body').on('click', '.sdg-submenu.db .has-child a', function () {
    if (window.matchMedia("(max-width: 800px)").matches) {
      $(this).parent().toggleClass('open');
    }
  })


  $('body').on('click', '.directory-col ul .expander', function (e) {
    e.preventDefault();
    $(this).parent().toggleClass('open');
  })

  $('body').on('click', '.modal-wrapper .modal .close', function (e) {
    e.preventDefault();
    $(this).closest('.modal-wrapper').removeClass('open');
    $(this).closest('.modal').removeClass('active');

  })

  $('body').on('change', '#relatedContentSort', function () {
    let tabName = $(this).val();
    $(this).parent().find('.related-content__wrapper #' + tabName).addClass('active').siblings().removeClass('active')
  })

  $('body').on('click', '.sdg-faq-item .question', function () {
    $(this).parent().toggleClass('open')
  })

  $('body').on('click', '.calculator-result-wrapper .result-expander a', function (e) {
    e.preventDefault();
    $(this).closest('.calculator-result-wrapper').toggleClass('open');
    $(this).html() == 'Zobrazit podrobnosti výpočtu' ? $(this).html('Skrýt podrobnosti výpočtu') : $(this).html('Zobrazit podrobnosti výpočtu');
    if ($(this).next().hasClass('fa-chevron-down')) {
      $(this).next().removeClass('fa-chevron-down').addClass('fa-chevron-up')
    } else {
      $(this).next().removeClass('fa-chevron-up').addClass('fa-chevron-down')
    }

  })

  $('body').on('click', '.close-accordion', function (e) {
    e.preventDefault();
    e.stopPropagation();
    $(this).closest('.wp-block-pb-accordion-item').removeClass('is-open');
    $(this).closest('.c-accordion__content').hide();
  })

  $('body').on('click', '.article-detail.directory .header .toggler', function (e) {
    e.preventDefault();
    e.stopPropagation();
    if ($(this).hasClass('close-all')) {
      $(this).removeClass('close-all');
      $(this).closest('.article-detail').find('.wp-block-pb-accordion-item').each(function () {
        $(this).removeClass('is-open').find('.c-accordion__content').hide();
      })
    } else {
      $(this).addClass('close-all');
      $(this).closest('.article-detail').find('.wp-block-pb-accordion-item').each(function () {
        $(this).addClass('is-open').find('.c-accordion__content').show();
      })
    }
  })

  $('.wp-block-table').on('scroll', function () {
    if ($(this).scrollLeft() != 0) {
      $(this).removeClass('show-scroll');
    } else {
      $(this).addClass('show-scroll');
    }
  })

  let applyScrollBars = function () {
    $('.wp-block-table').each(function () {
      if (($(this).get(0).scrollWidth > (($(this).innerWidth()) + 1)) && $(this).scrollLeft() == 0) {
        $(this).addClass('show-scroll');
      } else {
        $(this).removeClass('show-scroll');
      }
    });
  }

  applyScrollBars();

  /* sidebar submenu */
  $('body').on('click', '.sidebar-menu ul li span', function () {
    let list = $(this).parent()
    if (list.hasClass('menu-item-has-children')) {
      list.toggleClass('active');
    }
  })

  $('body').on('click', '.sidebar-nav-responsive-btn', function () {
    $('.sidebar-nav-responsive-wrapper').addClass('show');
  })

  $('body').on('click', '.sidebar-nav .close-btn, .sidebar-nav .responsive-button', function () {
    $('.sidebar-nav-responsive-wrapper').removeClass('show');
  })

  /* Modal for testing */
  $('body').on('click', 'a[data-modal]', function (e) {
    e.preventDefault();
    e.stopPropagation();
    let modalToOpen = $(this).attr('data-modal');
    $('.modal-wrapper').addClass('open')
    $('.modal-wrapper ' + modalToOpen).addClass('active');
    $('.modal.active').find('input').filter(':first').focus();
  })
  /* end Modal for testing */

  $('a[href$=".jpg"] img[class^="wp-image"], a[href$=".jpeg"] img[class^="wp-image"], a[href$=".png"] img[class^="wp-image"]').each(function () {
    $(this).parent().fancybox({
      mobile: {
        clickSlide: function () {
          return 'close';
        },
      },
    });
  });

  $('.wp-block-gallery').each(function (i) {
    let images = $(this).find('li a');

    images.each(function () {
      let caption = $(this).next('figcaption');

      $(this).attr('data-fancybox', 'gallery-' + i);
      $(this).attr('data-caption', caption.text());
    });
  });

  $('.wp-block-gallery li a, a.fancybox').fancybox({
    mobile: {
      clickSlide: function () {
        return 'close';
      },
    },
    autoplay: true,
  });

  if (!window.localStorage.getItem('hideNewsletterWidget')) {
    $('.newsletter-widget').show();
  }

  $('body').on('click', 'a[href="#zacatek"]', function () {
    if($('.chapters').length) {
      $('html, body').animate({
        scrollTop: $('.chapters').eq(0).offset().top - 130
      }, 'easy');
    }
  });
});
