$(document).ready(function () {
  $('canvas.graph-line').each(function () {
    var canvas = this;
    var ctx = canvas.getContext('2d');

    var labels = canvas.dataset.graphXs.split('|');
    var datasets = canvas.dataset.graphDatasets.split('|');
    var data = {
      labels: labels,
      datasets: datasets.map(function (dataset) {
        var parts = dataset.split(';');
        return {
          label: parts[0],
          borderColor: parts[1],
          backgroundColor: parts[1],
          data: parts.slice(2).map(parseFloat)
        };
      })
    };

    var options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        title: {
          display: true,
          text: 'Vývoj HDP (meziročně, %)'
        }
      }
    };

    var chart = new Chart(ctx, {
      type: 'line',
      data: data,
      options: options
    });
  });

  $('canvas.graph-pie').each(function () {
    var canvas = this;
    var ctx = canvas.getContext('2d');

    var labels = canvas.dataset.graphXs.split('|');
    var data = canvas.dataset.graphYs.split('|').map(parseFloat);
    var colors = canvas.dataset.graphColors.split('|');

    var pieData = {
      labels: labels,
      datasets: [{
        data: data,
        backgroundColor: colors
      }]
    };

    var options = {
      responsive: true,
      maintainAspectRatio: true,
      plugins: {
        title: {
          display: true,
          text: 'Podíl sektorů na HDP (%)'
        }
      }
    };

    var chart = new Chart(ctx, {
      type: 'pie',
      data: pieData,
      options: options
    });
  });
});
