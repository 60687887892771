$(function () {
  function logVisit(visit) {
    let searchParams = new URLSearchParams(window.location.search);

    searchParams.delete('pg');
    let savedUrl = window.location.pathname + '?' + searchParams.toString();

    if (typeof (sessionStorage) !== 'undefined' && !visit) {
      let searchedUrls = sessionStorage.searchedUrls;

      if (searchedUrls) {
        searchedUrls = $.parseJSON(searchedUrls);

        if ($.inArray(savedUrl, searchedUrls) > -1) {
          return;
        }

        searchedUrls.push(savedUrl);
      } else {
        searchedUrls = [];
        searchedUrls.push(savedUrl);
      }

      sessionStorage.searchedUrls = JSON.stringify(searchedUrls);
    }

    if (visit) {
      $.ajax({
        method: 'POST',
        url: '/wp-admin/admin-post.php',
        data: {
          post: $('input[name="visit-post"]').val(),
          nonce: $('input[name="visit-nonce"]').val(),
          action: 'visit-detail',
        },
      });
    } else {
      $.ajax({
        method: 'POST',
        url: '/wp-admin/admin-post.php',
        data: {
          url: window.location.href,
          nonce: $('input[name="search-nonce"]').val(),
          action: 'search-vertical',
        },
      });
    }
  }

  let visit = $('#visit-post').length ? parseInt($('#visit-post').val()) : -1;
  visit = visit > -1 ? visit : $('#search-vertical').length ? 0 : -1;

  if (visit > -1) {
    let botPattern = '(googlebot/|bot|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis)';
    let re = new RegExp(botPattern, 'i');
    let userAgent = navigator.userAgent;

    if (!re.test(userAgent)) {
      let moved = false;

      $(document).on('mousemove', function () {
        if (!moved) {
          setTimeout(function () {
            logVisit(visit);
          }, 1000);
        }

        moved = true;
      });

      $('body').on('touchmove', function () {
        if (!moved) {
          setTimeout(function () {
            logVisit(visit);
          }, 1000);
        }

        moved = true;
      });

      $(window).on('scroll', function () {
        if (!moved) {
          setTimeout(function () {
            logVisit(visit);
          }, 1000);
        }

        moved = true;
      });
    }
  }

  if(typeof window.__gaTracker !== 'undefined') {
    $('body.single').on('click', '.related-content__wrapper article a', function (e) {
      var event = $('body').hasClass('single-vertical') ? 'click_lists' : 'click_detail_other';
      var link = $(this).prop('href');
      var position = $(this).parent('article').index() + 1;
      var cpt = $('body').data('cpt');

      window.__gaTracker('send', 'event', 'cta', event, event, {
        'dimension1': cpt,
        'dimension2': position,
        'event_callback': function () {
          window.location.href = link;
        }
      });
    });

    $('body.single').on('click', '.related-content__wrapper .more', function (e) {
      var event = $('body').hasClass('single-vertical') ? 'click_pagination_lists' : 'click_pagination_detail';
      var cpt = $('body').data('cpt');

      window.__gaTracker('send', 'event', 'cta', event, event, {
        'dimension1': cpt,
      });
    });

    $('body.single').on('click', '.related-articles__wrapper article a', function (e) {
      var link = $(this).prop('href');
      var position = $(this).parent('article').index() + 1;
      var cpt = $('body').data('cpt');

      window.__gaTracker('send', 'event', 'cta', 'click_detail_related', 'click_detail_related', {
        'dimension1': cpt,
        'dimension2': position,
        'event_callback': function () {
          window.location.href = link;
        }
      });
    });

    $('body.single').on('click', '.taxonomy-theme a:not(.more), section.taxonomy a', function (e) {
      var link = $(this).prop('href');
      var taxonomy = $(this).closest('.taxonomy-theme').length ? $(this).parent().parent().parent().find('[data-theme]').data('theme') : $(this).parent().data('taxonomy');
      var cpt = $('body').data('cpt');

      window.__gaTracker('send', 'event', 'cta', 'click_filter_taxonomy_detail', 'click_filter_taxonomy_detail', {
        'dimension1': cpt,
        'dimension3': taxonomy,
        'event_callback': function () {
          window.location.href = link;
        }
      });
    });

    $('body.single').on('click', '.article-detail-info a.more', function (e) {
      var link = $(this).prop('href');
      var cpt = $('body').data('cpt');

      window.__gaTracker('send', 'event', 'cta', 'click_more_categories', 'click_more_categories', {
        'dimension1': cpt,
        'event_callback': function () {
          window.location.href = link;
        }
      });
    });

    $('body.single-vertical').on('change', '.filter__body label.checkbox input[type="checkbox"]', function (e) {
      var taxonomy = $(this).closest('.category-list').data('group');
      var cpt = $('body').data('cpt');

      window.__gaTracker('send', 'event', 'cta', 'click_filter_taxonomy_lists', 'click_filter_taxonomy_lists', {
        'dimension1': cpt,
        'dimension3': taxonomy,
      });
    });

    $('body').on('click', '.most-filtered a[data-taxonomy]', function (e) {
      var link = $(this).prop('href');
      var taxonomy = $(this).data('taxonomy');
      var cpt = $('body').data('cpt');

      window.__gaTracker('send', 'event', 'cta', 'click_most_filtered', 'click_most_filtered', {
        'dimension1': cpt,
        'dimension3': taxonomy,
        'event_callback': function () {
          window.location.href = link;
        }
      });
    });

    $('body').on('click', '.hp-recommended article a', function (e) {
      var position = $(this).parent('article').index() + 1;
      var link = $(this).prop('href');

      window.__gaTracker('send', 'event', 'cta', 'hp_recommended', 'hp_recommended', {
        'dimension2': position,
        'event_callback': function () {
          window.location.href = link;
        }
      });
    });

    $('body').on('click', '.most-articles div:nth-child(2) a', function (e) {
      var position = $(this).index() + 1;
      var link = $(this).prop('href');

      window.__gaTracker('send', 'event', 'cta', 'hp_most_read', 'hp_most_read', {
        'dimension2': position,
        'event_callback': function () {
          window.location.href = link;
        }
      });
    });

    $('body').on('click', '.most-articles div:last-child a', function (e) {
      var position = $(this).index() + 1;
      var link = $(this).prop('href');

      window.__gaTracker('send', 'event', 'cta', 'hp_most_searched', 'hp_most_searched', {
        'dimension2': position,
        'event_callback': function () {
          window.location.href = link;
        }
      });
    });

    $('body').on('click', '.hp-video-slider a, hp-mobile-video-slider a', function (e) {
      window.__gaTracker('send', 'event', 'cta', 'hp_video_slider', 'hp_video_slider', {

      });
    });

    $('html').on('click', 'body.single-vertical .article-right-column .right-col-slider-item a', function (e) {
      var cpt = $('body').data('cpt');

      window.__gaTracker('send', 'event', 'cta', 'list_right_col_video_slider', 'list_right_col_video_slider', {
        'dimension1': cpt,
      });
    });

    $('html').on('click', 'body.single:not(.single-vertical) .article-right-column .js-article-right-slider a', function (e) {
      var cpt = $('body').data('cpt');

      window.__gaTracker('send', 'event', 'cta', 'detail_right_col_video_slider', 'detail_right_col_video_slider', {
        'dimension1': cpt,
      });
    });
  }
});
