let familyCompaniesSearch = function () {
  if (!jQuery.expr[':'].icontains) {
    jQuery.expr[':'].icontains = function (a, i, m) {
      return jQuery(a).text().toUpperCase().indexOf(m[3].toUpperCase()) >= 0;
    };
  }

  let writeTimeout;
  let searchInput = $('.company-finder__input-wrapper input#company');
  let rowsContainer = $('.company-finder__table table tbody'); // Initial
  let originalRows = rowsContainer.clone();
  let first12Rows = rowsContainer.find('tr:lt(12)');
  let paginator = $('.company-finder .more');
  let showLetters = $('.company-finder__alphabet-filter .order-results');
  let noResults = $('.company-finder__no-results');
  let closeIcon = $('.company-finder__input-wrapper .input-group svg');
  let filterSymbol = $('.alphabet-filter__symbol');
  

  // Česká abeceda včetně čísel a speciálních znaků
  const czechAlphabet = [
    '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'Á', 'B', 'C', 'Č', 'D', 'Ď',
    'E', 'É', 'Ě', 'F', 'G', 'H', 'CH', 'I', 'Í', 'J', 'K', 'L', 'M', 'N', 'Ň', 'O', 'Ó', 'P',
    'Q', 'R', 'Ř', 'S', 'Š', 'T', 'Ť', 'U', 'Ú', 'Ů', 'V', 'W', 'X', 'Y', 'Ý', 'Z', 'Ž'
  ];

  // Funkce pro detekci mobilního nebo tabletového zařízení
  function isMobileOrTablet() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  // Funkce pro resetování původní tabulky
  function resetRowsContainer() {
    rowsContainer.replaceWith(originalRows.clone());
    rowsContainer = $('.company-finder__table table tbody'); // Aktualizace reference
    first12Rows = rowsContainer.find('tr:lt(12)'); // Aktualizace reference na první řádky
    rowsContainer.find('tr').addClass('d-none');
    first12Rows.removeClass('d-none');
  }

  // Funkce pro normalizaci českých znaků (např. Ř -> R, Č -> C)
  function normalizeCzechChars(char) {
    if (!char.normalize) return char.toUpperCase(); // Fallback pro starší prohlížeče
    return char.toUpperCase().normalize('NFD').replace(/[̀-ͯ]/g, '');
  }

  // Funkce pro správu třídy active a filtrování podle české abecedy
  $('.company-finder__alphabet-filter').on('click', '.alphabet-filter__symbol', function () {
    closeIcon.hide();
    noResults.hide();
    if ($(this).hasClass('active')) {
      $(this).removeClass('active');
      resetRowsContainer();
      paginator.show();
      return;
    }

    $('.company-finder__alphabet-filter .alphabet-filter__symbol').removeClass('active');
    $(this).addClass('active'); //přidá třídu active na konkrétní element
    searchInput.val(''); // Vymaže obsah vstupního pole

    // Získání hodnoty z data-symbol
    const selectedSymbol = $(this).data('symbol');
    if (!selectedSymbol) return;

    // Normalizace symbolu
    const normalizedSelectedSymbol = normalizeCzechChars(selectedSymbol.toString());

    // Filtr a zobrazení odpovídajících řádků
    rowsContainer.find('tr').addClass('d-none');

    // Řazení řádků podle české abecedy
    const sortedRows = rowsContainer.find('tr').sort(function (a, b) {
      const aChar = ($(a).data('sort') || '').charAt(0).toUpperCase();
      const bChar = ($(b).data('sort') || '').charAt(0).toUpperCase();

      const aIndex = czechAlphabet.indexOf(aChar);
      const bIndex = czechAlphabet.indexOf(bChar);

      return aIndex - bIndex;
    });

    // Přeskládat tabulku podle seřazených řádků
    rowsContainer.append(sortedRows);

     // Zobraz řádky podle zvoleného písmena/čísla
    rowsContainer.find('tr').filter(function () {
      const dataSortValue = $(this).data('sort');
      if (!dataSortValue) return false;

      // Zahrnutí speciálního případu pro "CH"
      if (selectedSymbol === 'CH') {
        return normalizeCzechChars(dataSortValue.toUpperCase()).startsWith('CH');
      }

      // První znak řádku
      const firstChar = dataSortValue.charAt(0).toUpperCase();
      const normalizedChar = normalizeCzechChars(firstChar);

      return normalizedChar === normalizedSelectedSymbol;
    }).removeClass('d-none');

    // Skrýt paginator
    paginator.hide();
  });

  // Zabraňuje ztrátě focusu na input
  closeIcon.on('mousedown', function (e) {
    e.preventDefault(); 
  });

  //vymazat input
  closeIcon.on('click', function () {
    searchInput.val(''); // Vymaže obsah inputu
    resetRowsContainer(); // Provede reset
    closeIcon.hide(); // Skryje closeIcon
    noResults.hide(); // Skryje noResults
    paginator.show(); // Zobrazí paginator
  });


  // Zobrazit firmy podle... (tlacitko - mobil)
  showLetters.on('click', function () {
    const parent = $(this).parent();
    parent.toggleClass('visible');
    // Odstranění výběru nebo focusu ze všech následujících divů
    parent.nextAll('div').each(function () {
      window.getSelection().removeAllRanges();
    });
  });

  //pokud je mobil - odstranit hover
  if (isMobileOrTablet()) {
    filterSymbol.addClass('no-hover');
  }

  // Při odentrování ztratit focus z inputu
  searchInput.on('keydown', function (e) {
    if (e.key === 'Enter') {
      e.preventDefault();
      $(this).blur();
    }
  });

  // Změna inputu
  searchInput.on('keyup', function () {
    $('.company-finder__alphabet-filter .alphabet-filter__symbol').removeClass('active');
    clearTimeout(writeTimeout);

    writeTimeout = setTimeout(function () {
      let value = searchInput.val();

      rowsContainer.find('tr').addClass('d-none');

      if (value.length > 0) {
        paginator.hide();
        let foundRows = rowsContainer.find('tr:icontains(' + value + ')');
        foundRows.removeClass('d-none');
        if (!foundRows.length > 0) {
          noResults.show();
        } else {
          noResults.hide();
        }
        closeIcon.show();
      } else {
        resetRowsContainer();
        closeIcon.hide();
        noResults.hide();
        paginator.show();
      }
    }, 300);
  });

  paginator.on('click', function (e) {
    e.preventDefault();

    rowsContainer.find('tr.d-none').slice(0, 12).removeClass('d-none');

    if (!rowsContainer.find('tr.d-none').length) {
      $('.family-company-search-paginator').hide();
    }
  });
};

$(document).ready(familyCompaniesSearch);
