document.addEventListener('DOMContentLoaded', function() {
	const tooltips = document.querySelectorAll('.js-tooltip');

	tooltips.forEach(tooltip => {
		tooltip.addEventListener('mouseenter', function() {
			const tooltipText = this.getAttribute('data-tooltip');
			const tooltipBubble = document.createElement('div');
			tooltipBubble.className = 'tooltip-bubble';
			tooltipBubble.textContent = tooltipText;
			this.appendChild(tooltipBubble);
			//dynamicky spočítat šířku tooltip + umístění šipky      
			updateMaxWidthEvent(tooltipBubble);
		});

		tooltip.addEventListener('mouseleave', function() {
			const tooltipBubble = this.querySelector('.tooltip-bubble');
			if (tooltipBubble) {
				tooltipBubble.remove();
			}
		});
	});

	//Dynamické počítání šířky tooltipu a umístění šipky (v detailu prispevku) 
	function updateMaxWidthEvent(tooltipBubble) {
		const featuredImage = document.querySelector(".article-detail-featured-image");
		const eventThemeGroup = document.querySelector(".article-detail-table");
		const moreCountries = document.querySelector(".more-countries");

		// Kontrola, jestli všechny elementy existují
		if (!eventThemeGroup || !moreCountries || !tooltipBubble) {
			return;
		}

		// Získání souřadnic
		const eventThemeGroupRect = eventThemeGroup.getBoundingClientRect();
		const moreCountriesRect = moreCountries.getBoundingClientRect();
		const featuredImageRect = featuredImage.getBoundingClientRect();

		// Získání šířky okna
		const windowWidth = window.innerWidth;

		if (windowWidth < 750) {
			tooltipBubble.style.left = `${eventThemeGroupRect.left - moreCountriesRect.left}px`;
			tooltipBubble.style.width = `${eventThemeGroup.offsetWidth}px`;
			tooltipBubble.style.maxWidth = "";
			// Přidání CSS custom property na tooltipBubble
			tooltipBubble.style.setProperty('--tooltip-left', `${moreCountriesRect.left + 6}px`);
		} else if (windowWidth >= 750 && windowWidth < 800) {
			tooltipBubble.style.left = `${eventThemeGroupRect.left - moreCountriesRect.left}px`;
			tooltipBubble.style.width = `${eventThemeGroup.offsetWidth}px`;
			tooltipBubble.style.maxWidth = "";
			// Přidání CSS custom property na tooltipBubble
			tooltipBubble.style.setProperty('--tooltip-left', `${moreCountriesRect.left - featuredImageRect.width - 6 }px`);
		} else {
			tooltipBubble.style.width = "";
			const availableWidth = Math.max(0, eventThemeGroupRect.right - moreCountriesRect.left);

			if (availableWidth > 0) {
				tooltipBubble.style.maxWidth = `${availableWidth - 14}px`;
			}

			// Reset left a top
			tooltipBubble.style.left = "";
			tooltipBubble.style.top = "";
		}
	}

});