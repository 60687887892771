$(document).ready(function () {

  let calcArr = [];

  let calcForm = $('#calculator-form')

  if (calcForm.length) {
    calcForm.validate({
      focusInvalid: true,
      groups: {
        dates: 'startDate dueDays dueDate',
        amounts: 'contractAmount targetContractAmount'
      },
      rules: {
        contractAmount: {
             required: '#targetContractAmount:blank',
             min: 1
        },
        targetContractAmount: {
             required: '#contractAmount:blank',
             min: 1
        },
        dueDate: {
          min: true
        }
      },
      messages: {
        contract: {
          required: 'Musí být vybrána jedna z možností'
        },
        targetContractAmount: {
          required: 'Vyplňte jednu z částek kontraktu',
          min: 'Částka musí být vyšší než 0'
        },
        contractAmount: {
          required: 'Vyplňte jednu z částek kontraktu',
          min: 'Částka musí být vyšší než 0'
        },
        startDate: {
          required: 'Vyplňte všechna data'
        },
        dueDays: {
          required: 'Vyplňte všechna data'
        },
        dueDate: {
          required: 'Vyplňte všechna data',
          min: 'Datum konce kontraktu musí být vyšší než začátek kontraktu'
        },

      },
      errorPlacement: function (error, element) {
        error.appendTo(element.closest('.input-wrapper, .radio-wrapper, .calculator-inputs').find('label .error-label'));
      }
    })

    $.validator.addMethod("min", function(e) {
      let startDate = $('#startDate').datepicker('getDate');
      let dueDate =  $('#dueDate').datepicker('getDate');
      if (startDate > dueDate) {
          return false;
      }
      return true;
  });

    $('[data-toggle="datepicker"]').datepicker({
      format: 'dd.mm.yyyy',
      autoHide: true,
      language: 'cs-CZ'
    });

    var $startDate = $('#startDate');
    var $dueDate = $('#dueDate');

    $startDate.datepicker({
      autoHide: true,
    });
    $dueDate.datepicker({
      autoHide: true,
      startDate: $startDate.datepicker('getDate'),
    });

    $startDate.on('change', function () {
      var startday = new Date($("#startDate").val().split(".").reverse().join(","));
      var dueday = new Date($("#dueDate").val().split(".").reverse().join(","));
      if(startday > dueday && dueday) {
        $dueDate.datepicker('setDate', $startDate.datepicker('getDate'));
      }
      $dueDate.datepicker('setStartDate', $startDate.datepicker('getDate'));
    });

    $('body').on('change', '.calculator-radios input', function() {
      $('input:checked').next('label').addClass('selected');
      $('input:not(:checked)').next('label').removeClass('selected');
    });
    $('body').on('keyup click', '#contractAmount', function() {
      $('#amounts-error').remove();
      if($(this).val().length) {
        $('#targetContractAmount').val('').prop('disabled', true)
      } else {
        $('#targetContractAmount').prop('disabled', false)
      }
    });
    $('body').on('keyup click', '#targetContractAmount', function() {
      $('.amount-error .error-label label').remove();
      if($(this).val().length) {
        $('#contractAmount').val('').prop('disabled', true)
      } else {
        $('#contractAmount').prop('disabled', false)
      }
    });
    $('body').on('click', '.switch', function(e) {
      e.preventDefault();
      let currency = $('#contractCurrency').val();
      let targetCurrency = $('#targetContractCurrency').val();
      $('#contractCurrency').val(targetCurrency);
      $('#targetContractCurrency').val(currency);
      calcArr = $('#calculator-form').serializeArray();
    });

    $('body').on('change', '#startDate, #dueDate', function() {
      if($('#dueDays').is(':focus')) {
        return
      }
      var firstday = new Date($("#startDate").val().split(".").reverse().join("-"));
      var lastday = new Date($("#dueDate").val().split(".").reverse().join("-"));
      $("#dueDays").val((lastday - firstday) / 86400000);
      $('.amount-error label').empty();
    });
    let inputTimeout
    $('body').on('keyup', '#dueDays', function() {
      clearTimeout(inputTimeout);
      let input = $(this);

      inputTimeout = setTimeout(function () {
          if($('#startDate').val()) {
            if(input.val()) {
              let days = parseInt($('#dueDays').val());
              let firstday = $('#startDate').datepicker('getDate');
              let dueday = firstday.setDate(firstday.getDate() + days)
              $('#dueDate').datepicker('setDate', new Date(dueday))
            } else {
              $('#dueDate').val('')
            }
          } else if ($('#dueDate').val()) {
            if(input.val()) {
              let days = parseInt($('#dueDays').val());
              let dueday = $('#dueDate').datepicker('getDate');
              let startday = dueday.setDate(dueday.getDate() - days)
              $('#startDate').datepicker('setDate', new Date(startday))
            } else {
              $('#startDate').val('')
            }
          }


      }, 500);
    });

    $('body').on('click', '.form-steps a', function (e) {
      e.preventDefault();
      let goTo = $(this).attr('data-step');
      if(calcForm.valid()) {
        calcArr = $('#calculator-form').serializeArray();
        fillRecap(calcArr)
        goToStep(goTo)
      } else {
        calcForm.validate().focusInvalid();
      }


    })

    $('body').on('click', '.calculator__input-wrapper a.next, .calculator__input-wrapper a.prev', function (e) {
      e.preventDefault();

        if (calcForm.valid() || !$(this).hasClass('next')) {
        let goTo = $(this).attr('data-step');
        calcArr = $('#calculator-form').serializeArray();
        if(goTo == 2) {
          fillRecap(calcArr)
        }
        goToStep(goTo);
        $('html, body').animate({
          scrollTop: $('.form-steps').offset().top
        }, 300);
      } else {
        calcForm.validate().focusInvalid();
      }


    })

    $.urlParam = function (name) {
      var results = new RegExp('[\?&]' + name + '=([^&#]*)')
        .exec(window.location.search);

      return (results !== null) ? results[1] || 0 : false;
    };

    let checkResult = function (guid) {
      guid = guid ? guid : $.urlParam('guid');

      if(!guid)
        return;

      let cycle = setInterval(function () {
        $.ajax({
          url: '/wp-admin/admin-post.php',
          type: 'post',
          dataType: 'json',
          data: {
            action: 'exchange-rate-risk-calculator-check-result',
            guid: guid,
          },
          success: function (data) {
            if (data.result) {
              clearInterval(cycle);
              window.location.reload();
            }
          },
        });
      }, 3000);
    };

    if ($('.calculator__input-wrapper.step3.step-is-loading').length) {
      checkResult();
    }

    let goToStep = function (num) {
        $('.calculator__input-wrapper.step' + num).addClass('active').siblings().removeClass('active');
        let currentStep = $('.form-steps a.step' + num);
        currentStep.addClass('active').removeClass('done');
        currentStep.prevAll().addClass('done').removeClass('active');
        currentStep.nextAll().removeClass('done active');

        if(num == 3) {
          let form = $('#calculator-form');

          $.ajax({
            url: form.attr('action'),
            type: 'post',
            dataType: 'json',
            data: form.serialize(),
            success: function (data) {
              if (data.id) {
                window.history.pushState(null, null, '?guid=' + data.id);
                checkResult();
              }
            },
          });
        }

        if(num == 4) {
          $('.form-steps a.step3').addClass('done')
        }
    }

    let fillRecap = function(arr) {
      $('.recap').empty();
      var obj = arr.reduce((arr, item) => ({ ...arr, [item.name]: item.value }), {})

      let daysText;
      if(obj.dueDays == 1) {
        daysText = 'den'
      } else if (obj.dueDays > 1 && obj.dueDays < 5) {
        daysText = 'dny'
      } else {
        daysText = 'dní'
      }

      let contractType;
      if (obj.contract_type == 'claim') {
        contractType = 'Pohledávka'
      } else if (obj.contract_type == 'commitment') {
        contractType = 'Závazek'
      } else {
        contractType = 'Nedefinováno';
      }

      let priceInitial = obj.initial_amount;
      let priceTarget = obj.target_amount;
      let formattedPriceInitial = numberFormat(priceInitial);
      let formattedPriceTarget = numberFormat(priceTarget);

      let targetAmount;
      let initialAmount;

      if (obj.initial_amount) {
        initialAmount = `
          <div class="recap-item">
            <span class="step-title">Měna kontraktu a částka</span>
            <div class="recap-value">${formattedPriceInitial} ${obj.contract_currency}</div>
          </div>
          <div class="recap-item">
            <span class="step-title">Cílová měna</span>
            <div class="recap-value" id="recapTargetCurrency">${obj.final_currency}</div>
          </div>
        `;
      }

      if (obj.target_amount) {
        targetAmount = `
          <div class="recap-item">
            <span class="step-title">Měna kontraktu</span>
            <div class="recap-value">${obj.contract_currency}</div>
          </div>
          <div class="recap-item">
            <span class="step-title">Cílová měna a částka</span>
            <div class="recap-value" id="recapTargetCurrency">${formattedPriceTarget} ${obj.final_currency}</div>
          </div>
        `;
      }

      let recapTemp = `
          <div class="recap-item">
            <span class="step-title">Typ kontraktu</span>
            <div class="recap-value">${contractType}</div>
          </div>
          ${initialAmount ? initialAmount : ''}
          ${targetAmount ? targetAmount : ''}
          <div class="recap-item">
            <span class="step-title">Datum počátku</span>
            <div class="recap-value">${obj.contract_begin_at}</div>
          </div>
          <div class="recap-item">
            <span class="step-title">Počet dní do splatnosti</span>
            <div class="recap-value">${obj.contract_due_date} ${daysText}</div>
          </div>
          <div class="recap-item">
            <span class="step-title">Datum splatnosti</span>
            <div class="recap-value">${obj.contract_end_at}</div>
          </div>
      `;
      $('.recap').append(recapTemp);

    }
  }

  function numberFormat(value) {
    let numericValue = parseFloat(value);
    if (isNaN(numericValue)) {
        //console.error('Hodnota není platné číslo');
        return value;
    }
    let roundedValue = Math.round(numericValue);
    let formattedValue = roundedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    return formattedValue;
}

})


