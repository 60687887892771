$(document).ready(function() {
  //console.log('thematic-newsletter.js loaded');

  var floatedNewsletterForm = $('#floatedNewsletterForm');

  // Floated Newsletter Subscribe
  if (floatedNewsletterForm.length) {
    floatedNewsletterForm.validate({
      focusInvalid: true,
      errorClass: "error",
      messages: {
        thematic_nl_email: {
          required: 'Toto pole je povinné',
          email: 'Zadejte emailovou adresu'
        },
      }
    })
  }

  floatedNewsletterForm.bind("invalid-form.validate", function () {
    $('.modal-wrapper').addClass('open');
    $('#thematic-newsletter-warning').addClass('active').closest('.modal-wrapper').addClass('open');
  });

  $('#thematic-newsletter-warning').on('click', '.close', function (e) {
    $("#thematicNlEmail").focus();
  })

  /* floatedNewsletter */
  if ($(".floatedNewsletter").length > 0) {

    /* open zalozek v floatedNewsletter */
    document.body.addEventListener('click', function(event) {
        if (event.target.matches('.floatedNewsletter .js-toggle-content')) {
            const clickedElement = event.target.parentElement;
            const isAlreadyOpen = clickedElement.classList.contains('open');
            const allToggleElements = document.querySelectorAll('.floatedNewsletter .js-toggle-content');
            allToggleElements.forEach(function(toggleElement) {
                toggleElement.parentElement.classList.remove('open');
            });
            if (!isAlreadyOpen) {
                clickedElement.classList.add('open');
            }
        }
    });

    /* plovoucí box, který se nabízí po přečtení  ⅔ obsahu anebo po 30% */
    window.addEventListener("scroll", function () {
      const floatedNewsletter = document.querySelector(".floatedNewsletter");

      if (floatedNewsletter.classList.contains('d-none')) {
          let endChapters = document.getElementById("endchapters");

          if (!endChapters) {
              endChapters = document.querySelector(".article-content");
          }

          if (!endChapters) {
              return;
          }
          const rect = endChapters.getBoundingClientRect();
          const windowHeight = window.innerHeight;
          const targetPosition = windowHeight / 3; // 1/3 od vrchu okna

          if (rect.top <= targetPosition) {
              var isClosed = sessionStorage.getItem('floatedNewsletterClosed');

              if (isClosed === 'true') {
                  // prvek se nezobrazí, tento stav zůstane dokud neuzavřu okno nebo prohlížeč, potom se zobrazí znovu
                  return;
              } else {
                  floatedNewsletter.classList.remove('d-none');

                  if (typeof window.__gaTracker !== 'undefined') {
                      var link = floatedNewsletter.getAttribute('data-link');
                      var type = floatedNewsletter.getAttribute('data-type');
                      var cpt = document.body.getAttribute('data-cpt');

                      window.__gaTracker('send', 'event', 'cta', 'view_containerNL', 'view_containerNL', {
                          'dimension1': cpt,
                          'dimension2': link,
                          'dimension3': type
                      });
                  }
              }
          }
        }
    });


    if (floatedNewsletterForm.length > 0) {

      $('body').on('click', '.js-close-floated-newsletter', function (e) {
        e.preventDefault();
        e.stopPropagation();
        //$(this).closest('.floatedNewsletter').remove(); // remove element
        $(this).closest('.floatedNewsletter').addClass('d-none'); // hide element

        if (typeof window.__gaTracker !== 'undefined') {
          var link = $('.floatedNewsletter').data('link');
          var type = $('.floatedNewsletter').data('type');
          var cpt = $('body').data('cpt');

          window.__gaTracker('send', 'event', 'cta', 'close_containerNL', 'close_containerNL', {
            'dimension1': cpt,
            'dimension2': link,
            'dimension3': type,
          });
        }

        sessionStorage.setItem('floatedNewsletterClosed', 'true');
      })

      $('body').on('submit', 'form#floatedNewsletterForm', function (e) {
        e.preventDefault();
        let form = $(this);
        form.find('button.nl-save-btn').addClass('is-loading');
        $.ajax({
          type: 'POST',
          url: form.attr('action'),
          data: form.serialize(),
          success: function (data) {
            $('.modal-wrapper').addClass('open');
            $('#thematic-newsletter-' + data.status).addClass('active').closest('.modal-wrapper').addClass('open');
            if (data.status !== 'error') {
              let button = $('#floatedNewsletterForm input[type=submit], #floatedNewsletterForm button[type=submit]')
              button.addClass('success')
              button.html(`
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <path id="check-circle-solid" d="M32,20A12,12,0,1,1,20,8,12,12,0,0,1,32,20ZM18.612,26.354l8.9-8.9a.774.774,0,0,0,0-1.095L26.42,15.261a.774.774,0,0,0-1.095,0l-7.261,7.261-3.39-3.39a.774.774,0,0,0-1.095,0l-1.095,1.095a.774.774,0,0,0,0,1.095l5.032,5.032a.774.774,0,0,0,1.095,0Z" transform="translate(-8 -8)" fill="#fff"/>
                </svg> OK
              `);
              if (typeof window.__gaTracker !== 'undefined') {
                var link = $('.floatedNewsletter').data('link');
                var type = $('.floatedNewsletter').data('type');
                var cpt = $('body').data('cpt');

                window.__gaTracker('send', 'event', 'cta', 'sent_containerNL', 'sent_containerNL', {
                  'dimension1': cpt,
                  'dimension2': link,
                  'dimension3': type,
                });
              }
              sessionStorage.setItem('floatedNewsletterClosed', 'true');
              //$('.floatedNewsletter').remove(); // remove element
              $('.floatedNewsletter').addClass('d-none'); // hide element
              form[0].reset();
            }
          },
          error: function (data) {
            console.log("error: ",data);
            /*let button = $('#floatedNewsletterForm input[type=submit], #floatedNewsletterForm button[type=submit]')
            button.addClass('success')
            button.html(`
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path id="check-circle-solid" d="M32,20A12,12,0,1,1,20,8,12,12,0,0,1,32,20ZM18.612,26.354l8.9-8.9a.774.774,0,0,0,0-1.095L26.42,15.261a.774.774,0,0,0-1.095,0l-7.261,7.261-3.39-3.39a.774.774,0,0,0-1.095,0l-1.095,1.095a.774.774,0,0,0,0,1.095l5.032,5.032a.774.774,0,0,0,1.095,0Z" transform="translate(-8 -8)" fill="#fff"/>
              </svg> OK
            `);
            setTimeout(() => {
              $('.floatedNewsletter').remove();
            }, 1500);*/
          }
        });
      });
    }
  }

});
