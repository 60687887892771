document.addEventListener("DOMContentLoaded", function() {
  // Vybereme všechny odstavce, které obsahují třídu 'long-on'
  const paragraphs = document.querySelectorAll('p.long-on');
  const blocks = document.querySelectorAll('div.long-on');

  function blockHighlight(moreBlock, typ) {
    moreBlock.forEach(paragraph => {
      const lineCountTest = parseInt(paragraph.getAttribute('data-row'), 10);
      if (!lineCountTest) {
        return;
      }

      const postContainer = document.createElement('div');
      postContainer.className = 'post-container';

      const postContent = document.createElement('div');
      postContent.className = 'post-content';

      const wrapperDiv = document.createElement('div');
      wrapperDiv.className = paragraph.className;

      const showMoreBtn = document.createElement('button');
      showMoreBtn.className = 'show-more-btn';
      showMoreBtn.textContent = 'Zobrazit vše';

      const paragraphContent = document.createElement(typ);
      paragraphContent.classList.add('long-on-block');
      paragraphContent.innerHTML = paragraph.innerHTML;
      paragraphContent.setAttribute('data-row', paragraph.getAttribute('data-row'));

      // Nastavení maximální výšky podle počtu řádků
      const lineCount = parseInt(paragraphContent.getAttribute('data-row'), 10);
      paragraphContent.style.overflow = 'hidden';
      paragraphContent.style.maxHeight = `${lineCount * 1.5}em`; // Předpoklad výšky řádku je 1.2em

      wrapperDiv.appendChild(paragraphContent);
      wrapperDiv.appendChild(showMoreBtn);

      postContent.appendChild(wrapperDiv);
      postContainer.appendChild(postContent);

      paragraph.parentNode.insertBefore(postContainer, paragraph.nextSibling);
      paragraph.remove();

      showMoreBtn.addEventListener('click', function () {
        wrapperDiv.classList.remove('long-on');
        paragraphContent.classList.remove('long-on-block');
        paragraphContent.style.maxHeight = 'none'; // Zruší omezení výšky
        showMoreBtn.classList.add('hidden');
      });
    });
  }

  blockHighlight(paragraphs, 'p');
  blockHighlight(blocks, 'div');

});
