"use strict";

/* vygeneruje HTML pro jednotlivé položky filtru <li> */
let renderFilter = function (response, refresh) {
  if (refresh) {
    $('.js-tbc-1-filter').html('');
  }

  //console.log(response.taxonomies);

  localStorage.removeItem('allFilterSearchTerms');
  localStorage.setItem('allFilterSearchTerms', JSON.stringify(response.taxonomies));
  //localStorage.setItem('allFilterSearchTerms', JSON.stringify(response));

  $.each(response.taxonomies, function (key, item) {
    let taxonomyRowTemp = $(`
      <li class="tbc-1-item filter--result-info__theme js-level_1" id="${item.taxonomy}" data-name="${item.name}" data-submenu="${item.terms && item.terms.length > 0 ? item.taxonomy + '_level_1' : ''}">
          <label class="tbc-1-item-name" data-theme="${item.taxonomy}" tabindex="0">
              ${item.name}
              ${item.terms && item.terms.length > 0 ? '<span class="tbc-1-icon-arrow"></span>' : ''}
          </label>
      </li>
    `);
    $('.js-tbc-1-filter').append(taxonomyRowTemp);
  });

  response.taxonomies.forEach(taxonomy => {
    processTermsToLocalStorage(taxonomy, taxonomy.terms);
  });
};

/* načte data pro filtr */
let loadFilter = function (refresh, query) {
  let filter = $('.filter');
  if (filter.length) {

    if (!query) {
      query = $.query.parseNew(location.search);
    }

    query = query.set('ajax', 1).set('load', 'filter');
    $.getJSON(query.toString())
      .done(function (response) {
        renderFilter(response, refresh);
      })
      .fail(function (error) {
        console.error('Chyba při načítání:', error);
      })
      .always(function () {
        // Loader se odstraní v renderFilter
        $('.js-filter-loader').remove();
      });
  }
};

/* načte data pro filtr - struktura vnoření nabídek */
function processTermsToLocalStorage(taxonomy, terms, parentSlug = '', level = 1) {
  if (!terms || !terms.length) return;

  let filterSearchTerms = JSON.parse(localStorage.getItem('allFilterSearchterms')) || [];

  let storageKey = parentSlug
    ? `${taxonomy.taxonomy}_${parentSlug}_level_${level}`
    : `${taxonomy.taxonomy}_level_${level}`;

  localStorage.removeItem(storageKey);

  // Vytvoření HTML pro aktuální úroveň
  const html = terms.map(term => `
        <li
          class="tbc-1-item js-item-checkbox filter--result-info__theme ${term.children && term.children.length > 0 ? `js-enter-level_${level + 1} js-space-level` : 'js-enter-level-none'}"
          id="${term.slug}" data-parent="${taxonomy.taxonomy}"
          tabindex="0"
          aria-label="${term.name}"
        >
            <input
              type="checkbox" class="tbc-1-checkbox js-filter-checkbox js-checkbox-level_${level + 1}"
              id="term-${term.id}"
              name="term-${term.slug}"
              data-term-id="${term.id}"
              data-term-slug="${term.slug}"
              data-parent="${parentSlug}"

            >
            <label
              for="term-${term.id}"
              class="tbc-1-item-name ${term.children && term.children.length > 0 ? `js-level_${level + 1}` : 'js-level-none'}"
              data-theme="${term.slug}"
              data-name="${term.name}"
              ${term.children && term.children.length > 0 ? `data-submenu="${taxonomy.taxonomy}_${term.slug}_level_${level + 1}"` : ''}
            >
              ${term.name}
              ${term.children && term.children.length > 0 ? '<span class="tbc-1-icon-arrow"></span>' : ''}
            </label>
        </li>
    `).join('');

  localStorage.setItem(storageKey, html);

  // Rekurzivní zpracování children
  terms.forEach(term => {
    if (term.children && term.children.length) {
      processTermsToLocalStorage(taxonomy, term.children, term.slug, level + 1);
    }
  });
}

//Zobrazení carouselů (video + audio)
let renderRelatedContent = function (response) {
  let mediaArr = response.audio_video;
  let articlesArr = response.posts;

  if (mediaArr.length == 0 && articlesArr.length == 0) {
    $('.article-content__wrapper').addClass('full-width')
  }

  if (mediaArr.length > 0) {
    let videoContent = ''; // Proměnná pro videa
    let audioContent = ''; // Proměnná pro audia

    mediaArr.forEach((item) => {
      //videa
      if (item.iframe_video && item.iframe_video.trim() !== '') {
        let videoUrl = item.iframe_video;
        let hrefAttributes = window.cookieConsent && window.cookieConsent.allowedCategory('third_party') ?
          `href="${videoUrl}" data-type="iframe" data-iframeAttr="autoplay; fullscreen" data-fancybox="video"` :
          `href="${item.link}" target="_blank"`;

        videoContent += `
            <div class="right-col-slider-item">
              <a ${hrefAttributes}>
                <div class="image">
                  <img src="${item.thumbnail}" alt="${item.title}">
                </div>
                <div class="title">
                  ${item.short_name ? item.short_name : item.title}
                </div>
              </a>
            </div>`;
        //audia
      } else if (item.iframe_audio && item.iframe_audio.trim() !== '') {
        let audioUrl = item.iframe_audio;
        let hrefAttributes = '';
        //spotify + appple podcasts => iframe
        if (item.audio_platform === 'link_spotify' || item.audio_platform === 'link_apple_podcasts') {
          hrefAttributes = window.cookieConsent && window.cookieConsent.allowedCategory('third_party') ?
            `href="${audioUrl}" data-type="iframe" data-iframeAttr="autoplay; fullscreen" data-fancybox="audio"` :
            `href="${item.link}" target="_blank"`;
        } else {
          //bez iframu
          hrefAttributes = `href="${audioUrl}" target="_blank"`;
        }

        audioContent += `
            <div class="right-col-slider-item audio">
              <a ${hrefAttributes}>
                <div class="image">
                  <img src="${item.thumbnail}" alt="${item.title}">
                </div>
                <div class="title">
                  ${item.short_name ? item.short_name : item.title}
                </div>
              </a>
            </div>`;
      }
    });

    // Po smyčce přidáme obsah do DOM pouze pokud existuje
    //video
    if (videoContent) {
      $('.right-col-video-slider.video').append(videoContent);
    } else {
      $('.js-list-right-slider.video').hide();
      $('.js-article-right-slider.video').hide();
    }

    //audio
    if (audioContent) {
      $('.right-col-video-slider.audio').append(audioContent);
    } else {
      $('.js-list-right-slider.audio').hide();
      $('.js-article-right-slider.audio').hide();
    }

    // Přidání tříd podle podmínek
    if (videoContent && !audioContent) {
      $('.right-col-video-slider.video').addClass('lowest-slider'); // Pokud existuje jen video
    } else if (!videoContent && audioContent) {
      $('.right-col-video-slider.audio').addClass('lowest-slider'); // Pokud existuje jen audio
    } else if (videoContent && audioContent) {
      $('.right-col-video-slider.audio').addClass('lowest-slider'); // Pokud existují obě, přidáme jen audiu
      $('.right-col-video-slider.video, .right-col-video-slider.audio').addClass('available'); // Přidáme available oběma (kvůli návaznosti css - nadpisů carouselů)
    }

    //přidáme slider pro single post
    //video
    if (videoContent) {
      if ($('.js-article-right-slider.video').length) {
        $('.js-article-right-slider.video').slick({
          dots: true,
          arrows: false,
          autoplay: true,
          infinite: false,
          slidesToShow: 1,
          slidetToScroll: 1,
          autoplaySpeed: 5000,
          responsive: [{
              breakpoint: 1050,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 799,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 575,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            }
          ]
        });
      }
    }

    //pro single post
    //audio
    if (audioContent) {
      if ($('.js-article-right-slider.audio').length) {
        $('.js-article-right-slider.audio').slick({
          dots: true,
          arrows: false,
          autoplay: true,
          infinite: false,
          slidesToShow: 1,
          autoplaySpeed: 8000,
          slidetToScroll: 1,
          responsive: [{
              breakpoint: 1050,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 799,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 575,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            }
          ]
        });
      }
    }


    //pro rubriky
    //video
    if (videoContent) {
      if ($('.js-list-right-slider.video').length) {
        $('.js-list-right-slider.video').slick({
          dots: true,
          arrows: false,
          autoplay: true,
          infinite: false,
          slidesToShow: 1,
          slidetToScroll: 1,
          autoplaySpeed: 5000,
          responsive: [{
              breakpoint: 1000,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 799,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 575,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            }
          ]
        });
      }

    }
    //audio
    if (audioContent) {
      if ($('.js-list-right-slider.audio').length) {
        $('.js-list-right-slider.audio').slick({
          dots: true,
          arrows: false,
          autoplay: true,
          infinite: false,
          slidesToShow: 1,
          slidetToScroll: 1,
          autoplaySpeed: 8000,
          responsive: [{
              breakpoint: 1000,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 799,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 575,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            }
          ]
        });
      }

    }

  }

  if (articlesArr.length > 0) {
    articlesArr.map((item) => {
      let article = `
      <a href="${item.link}">
        <div class="image">
          ${item.image}
        </div>
        <div class="title">${item.name}</div>
      </a>`
      $('.right-col-list').append(article);
    })
  }

  let createRelatedPosts = function (item) {
    let itemLink = item.link;
    let itemName = item.name;
    let itemVertical = item.vertical.code;
    let itemVerticalName = item.vertical.name;
    let itemText = item.excerpt;

    let label = item.label ? `<span class="updated">${item.label}</span>` : '';

    return `
      <article>
        <a href="${itemLink}" role="link" title="${itemName}">
          <section class="label ${itemVertical}">${itemVerticalName}</section>
          ${label}
          <h3>${itemName}</h3>
          <p>${itemText}</p>
        </a>
      </article>
    `;
  };

};

let appendNextPosts = function (response, removeOld) {
  if (removeOld) {
    $('.js-article-list__wrapper article, .js-article-list__wrapper .form-item').remove();
  }

  if (response.show_more_button) {
    $('.js-article-list__wrapper .js-load-more').attr('href', response.show_more_button).show();
  } else {
    $('.js-article-list__wrapper .js-load-more').hide();
  }

  $('.js-article-list__wrapper > .filter-result-info, .js-article-list__wrapper > .most-filtered, .js-article-list__wrapper > .filter-result-sorting').remove();
  $(response.status).insertAfter($('.js-article-list__wrapper .loader-wrapper'));

  let articleArr = response.postsList;
  let content = '';
  $.each(articleArr, function (key, item) {
    content += item;
  });

  $(content).insertBefore('.js-article-list__wrapper .js-load-more');

  document.querySelectorAll('img[srcset]').forEach(img => {
    const controller = new AbortController();

    img.addEventListener('error', () => {
      controller.abort();
      img.srcset = img.dataset.fallbackSrcset || '';
    }, {
      signal: controller.signal,
      once: true
    });
  });
};

/*let appendPrimaryPosts = function (response, removeOld) {
  console.log(response);
  if (removeOld) {
    $('.js-article-list__wrapper article, .js-article-list__wrapper .form-item').remove();
  }

  if (response.show_more_button) {
    $('.js-article-list__wrapper .js-load-more').attr('href', response.show_more_button).show();
  } else {
    $('.js-article-list__wrapper .js-load-more').hide();
  }

  $('.js-article-list__wrapper > .filter-result-info, .js-article-list__wrapper > .most-filtered, .js-article-list__wrapper > .filter-result-sorting').remove();
  $(response.status).insertAfter($('.js-article-list__wrapper .loader-wrapper'));

  let articleArr = response.posts;
  console.log(response.posts);

  $.each(articleArr, function (key, item) {

    let itemDate;
    let emptyArticle = "";

    if (item.vertical.code === 'events' || item.vertical.code === 'tenders') {
      itemDate = item.meta.date ? '<span class="date"><i class="fal fa-calendar-day"></i>' + item.meta.date + '</span>' : '';
    } else {
      itemDate = item.meta.date ? '<span class="date"><i class="fal fa-clock"></i>' + item.meta.date + '</span>' : '';
    }

    let itemType = item.meta.type ? '<span class="type"><i class="fal fa-tag"></i>' + item.meta.type + '</span>' : '';
    let itemPlace = item.meta.place ? '<span class="location"><i class="fal fa-map-marker-alt"></i>' + item.meta.place + '</span>' : '';

    let countries = [];

    $.each(item.meta.country, function (key, country) {
      countries.push(`<span class="country-item ${country.slug}"><img src='/wp-content/themes/businessinfo.cz.v2/dist/images/flags/${country.slug}.svg'> <span class="country-name">${country.name}</span></span>`)
    })

    let itemCountry = item.meta.country ? `<span class="country">${countries.join('')}</span>` : '';

    let label = item.label ? `<span class="label updated">${item.label}</span>` : '';

    if (item.meta.labels) {
      item.meta.labels.forEach((l) => {
        if (l == 'Velký týden malých firem') {
          label += `<span class="label velky-tyden-malych-firem"></span>`;
          emptyArticle = 'class="article-velky-tyden-malych-firem"';
        } else {
          label += `<span class="label source">Zdroj: <strong>${l}</strong></span>`;
        }
      })
    }

    if (item.excerpt == false && item.image == false) {
      emptyArticle = 'class="no-content"'
    }

    let temp;

    if (item.vertical.code === 'forms') {
      let documents = [];

      $.each(item.sources, function (key, doc) {
        documents.push(`<span class="label">${doc}</span>`);
      });

      temp = `
        <div class="form-item">
          <a href="${item.link}" role="link">
            <div class="title-wrapper">
              ${label}
              <h2>${item.name.replace('+', '<span class="plus">+<span>')}</h2>
            </div>
            <div class="form-info">
              <span class="institution">
                <i class="fal fa-university"></i>
                ${item.meta.institution}
                ${documents.join('')}
              </span>
            </div>
          </a>
        </div>
      `;
    } else {
      let articlePhoto = item.image ? `<div class="article-photo">${item.image}</div>` : '';

      temp = `
        <article ${emptyArticle}>
          <a href="${item.link}" role="link">
            <div class="title-wrapper">
              ${label}
              <h2>${item.name.replace('+', '<span class="plus">+<span>')}</h2>
            </div>
            <div class="article-info">
              ${itemDate}
              ${itemType}
              ${itemPlace}
              ${itemCountry}
            </div>
            ${articlePhoto}
            <div class="article-text">${item.excerpt}</div>
          </a>
        </article>
      `;
    }

    $(temp).insertBefore('.js-article-list__wrapper .js-load-more')
  })
};*/

let append404Posts = function (response, removeOld) {
  if (removeOld) {
    $('.error404__sidebar article').remove();
  }

  if (response.show_more_button) {
    $('.error404__sidebar .js-load-more').attr('href', response.show_more_button).show();
  } else {
    $('.error404__sidebar .js-load-more').hide();
  }

  let articleArr = response.posts;

  $.each(articleArr, function (key, item) {
    var itemDate;
    if (item.vertical.code === 'events' || item.vertical.code === 'tenders') {
      itemDate = item.meta.date ? '<span class="date"><i class="fal fa-calendar-day"></i>&nbsp;' + item.meta.date + '</span>' : '';
    } else {
      itemDate = item.meta.date ? '<span class="date"><i class="fal fa-clock"></i>&nbsp;' + item.meta.date + '</span>' : '';
    }
    let itemImage = item.image ? '<div class="img">' + item.image + '</div>' : '';
    let temp;

    temp = `
        <article>
          <a href="${item.link}">
          ${itemImage}
          <div class="title">
            ${item.name}
            ${itemDate}
          </div>
          </a>
        </article>
      </section>
    `;

    $('.error404__sidebar .recommended .recommended-articles').append($(temp));
  })
};

let appendRelatedPosts = function (response, tab) {

  if (response.by_categories.show_more_button) {
    $('.related-content-column .more').attr('href', response.by_categories.show_more_button);
  } else {
    $('.related-content-column .more').remove();
  }

  $.each(response.by_categories.posts, function (key, item) {
    let label = item.label ? `<span class="updated">${item.label}</span>` : '';

    const temp = `
      <article>
        <a href="${item.link}" role="link" title="${item.name}">
          <section class="label ${item.vertical.code}">${item.vertical.name}</section>
          ${label}
          <h3>${item.name}</h3>
          <p>${item.excerpt}</p>
        </a>
      </article>
    `;

    $(temp).insertBefore($('.related-content__wrapper .more'));
  })
};

let renderSimilarContent = function (response) {
  let similarArr = response.posts;

  $.each(similarArr, function (key, item) {
    let detail;

    if (item.vertical.code == 'tenders') {

      let countries = [];
      $.each(item.meta.country, function (key, country) {
        countries.push(country.name);
      })

      detail = `
        <div class="tender-detail">
          <span>Platnost nabídky do <strong>${item.meta.date}</strong></span>
          <span>${countries.join(', ')}</span>
        </div>
      `;
    } else if (item.vertical.code == 'events') {
      detail = `
      <div class="event-detail">
        <span><strong>${item.meta.date}</strong></span>
        <span>${item.meta.type}</span>
      </div>
      `;
    } else if (item.vertical.code == 'forms') {
      detail = `
      <div class="form-detail">
        <span>${item.meta.institution}</span>
        <span>Dokument: ${item.sources.join(' / ')}</span>
      </div>
      `;
    } else {
      detail = ``;
    }

    let temp = `
    <article>
      <a href="${item.link}" role="link">
        <div class="image-wrapper">
            ${item.image}
        </div>

        <span class="topic">${item.vertical.name}</span>
        <h4>${item.name}</h4>
        <div class="text">${item.excerpt}</div>
        ${detail}
      </a>
    </article>
    `;

    $('.related-articles__wrapper').append(temp);
  })
};

$(document).ready(function () {
  $.cachedScript('https://cdn.jsdelivr.net/gh/yeikos/jquery.history/jquery.history.js').done(function () {
    $.history.on('load change push pushed').listen();
  });

  $.cachedScript('https://cdn.jsdelivr.net/npm/jquery-query-object@2.2.3/jquery.query-object.min.js').done(function () {
    if ($('body').hasClass('error404')) {
      $.get($.query.parseNew(location.search).set('ajax-snippet', 'search').toString()).fail(function (data) {
        let errorContainer = $('main.error404');

        if ($.trim(data.responseText)) {
          errorContainer.find('.error404__sidebar').removeClass('loading').append(data.responseText);
        } else {
          errorContainer.removeClass('article-list').addClass('full');
          errorContainer.find('.error404__sidebar').remove()
        }
      });
    }

    loadMore();
    loadFilter();
    loadRelatedContent();
    loadSimilarContent();
    loadRightWidgetContent();
    lazyLoadSnippets();
  });
});

let loadMore = function () {
  $('body').on('click', '.js-article-list__wrapper .js-load-more', function (e) {
    e.preventDefault();

    let button = $(this);

    let url = button.attr('href');

    let query = $.query.parseNew(url.split("?").length > 1 ? url.split("?")[1] : "");
    query = query.set('ajax', 1);

    //return;

    button.addClass('is-loading');

    $.getJSON(query.toString(), function (response) {

      console.log(response);
      if (typeof $.history === 'function') {
        $.history.push(url);
      }

      let scrollPosition = $(window).scrollTop();
      //appendPrimaryPosts(response, false);
      appendNextPosts(response, false);

      button.removeClass('is-loading');

      $(document).scrollTop(scrollPosition);
    });
  });

  $('body').on('click', '.error404__sidebar .more', function (e) {
    e.preventDefault();

    let button = $(this);

    let url = button.attr('href');
    let query = $.query.parseNew(url.split("?").length > 1 ? url.split("?")[1] : "");
    query = query.set('ajax', 1);

    button.addClass('is-loading');

    $.getJSON(query.toString()).fail(function (response) {
      if (typeof $.history === 'function') {
        $.history.push(url);
      }

      let scrollPosition = $(window).scrollTop();

      append404Posts(response.responseJSON, false);

      button.removeClass('is-loading');

      $(document).scrollTop(scrollPosition);
    });
  });

  $('body').on('click', '.related-content-column .more', function (e) {
    e.preventDefault();

    let button = $(this);
    let tab = $(this).parent().attr('id');
    let url = button.attr('href');
    let query = $.query.parseNew(url.split("?").length > 1 ? url.split("?")[1] : "");
    query = query.set('ajax', 1).set('load', 'related-content');

    button.addClass('is-loading');

    $.getJSON(query.toString(), function (response) {
      let scrollPosition = $(window).scrollTop();

      appendRelatedPosts(response, tab);

      button.removeClass('is-loading');
      $(document).scrollTop(scrollPosition);
    });

  });
  $('body').on('click', '.gx-right-widget .more-articles .more', function (e) {
    e.preventDefault();
    let button = $(this);
    let url = button.attr('href');
    let query = $.query.parseNew(url.split("?").length > 1 ? url.split("?")[1] : "");
    query = query.set('ajax', 1).set('load', 'right-widget');

    button.addClass('is-loading');

    $.getJSON(query.toString(), function (response) {
      let scrollPosition = $(window).scrollTop();

      appendRightWidgetPosts(response);

      button.removeClass('is-loading');
      $(document).scrollTop(scrollPosition);
    });

  });
};

let loadRelatedContent = function (refresh, query) {
  let rightColumn;
  rightColumn = $('.article-right-column');
  if (rightColumn.length) {
    if (!query) {
      query = $.query.parseNew(location.search);
    }
    query = query.set('ajax', 1).set('load', 'related-content');
    //rightColumn.addClass('is-loading')
    $.getJSON(query.toString(), function (response) {
      renderRelatedContent(response);
      $('.article-right-column').removeClass('is-loading');
    });
  }
};

let loadRightWidgetContent = function () {
  //let url = 'https://bi-test.geetix.com/clanky/ruska-federace-planuje-docasne-zavest-exportni-cla-na-kovy/?ajax=1&load=right-widget'
  let widget = $('.gx-right-widget');
  if (widget.length) {
    let query = $.query.parseNew(location.search);
    query = query.set('ajax', 1).set('load', 'right-widget');
    $.getJSON(query.toString(), function (response) {
      renderRightWidgetContent(response);
    });

  }
};

let renderRightWidgetContent = function (response) {
  let postsArr;

  let createPosts = function (post) {
    return `
    <a href="${post.link}" role="link" class="article-link">
      <div class="image">
      ${post.image}
      </div>
      <div class="text-content">
        <div class="title">${post.name}</div>
        <div class="text">${post.excerpt}</div>
      </div>
    </a>
    `;
  }

  if (response && response.posts && typeof response.posts === 'object' && response.posts.items && Array.isArray(response.posts.items) && response.posts.items.length > 0) {
    postsArr = response.posts.items;
    let postContainer = `
    <div class="block articles">
      <h5>${response.posts.title}</h5>
      <div class="article-links"></div>
    </div>
    `;
    $('.gx-right-widget').append(postContainer);

    $.each(postsArr, function (key, post) {
      $('.gx-right-widget .block.articles .article-links').append(createPosts(post));
    });
  }
};


let appendRightWidgetPosts = function (response) {

  if (response.posts.show_more_button) {
    $('.gx-right-widget .more-articles .more').attr('href', response.posts.show_more_button);
  } else {
    $('.gx-right-widget .more-articles .more').remove();
  }

  $.each(response.posts.items, function (key, post) {

    const temp = `
    <a href="${post.link}" role="link" class="article-link">
      <div class="image">
      ${post.image}
      </div>
      <div class="text-content">
        <div class="title">${post.name}</div>
        <div class="text">${post.excerpt}</div>
      </div>
    </a>
    `;

    $(temp).insertBefore($('.gx-right-widget .more-articles'));
  })
};

let loadSimilarContent = function () {
  let related = $('.related-articles__wrapper');

  if (related.length) {
    let query = $.query.parseNew(location.search);
    query = query.set('ajax', 1).set('load', 'similar-content');

    $.getJSON(query.toString(), function (response) {
      renderSimilarContent(response);
    });
  }
};

let lazyLoadSnippets = function () {
  $('div[data-ajax]').bind('ajax:init', function (e, search, callback) {
    let target = $(this);

    let query = $.query.parseNew(search ? search : (target.data('params') ? target.data('params') : location.search));
    query = query.set('ajax', target.data('ajax'));

    $.get(query.toString(), function (result) {
      if ($.isFunction(callback)) {
        callback(result);
      } else if (result) {
        target.html(result);

        if (target.data('ajax') === 'custom-posts' && !target.find('article').length) {
          target.remove();
        }
      } else if (target.data('remove-empty')) {
        target.remove();
      }
    });
  });

  $('div[data-ajax]').each(function () {
    $(this).trigger('ajax:init');
  });

  $('body').on('click', '.custom-posts[data-ajax] a.page-numbers', function (e) {
    e.preventDefault();

    let container = $(this).parents('.custom-posts[data-ajax]');
    let dest = $(this).prop('href');

    $(this).addClass('is-loading');

    $.get(dest, function (result) {
      if (result) {
        container.html(result);

        $('html, body').animate({
          scrollTop: container.offset().top - 100,
        }, 400);
      }
    });
  });
};

jQuery.cachedScript = function (url, options) {
  options = $.extend(options || {}, {
    dataType: "script",
    cache: true,
    url: url
  });

  return jQuery.ajax(options);
};

$('body').on('click', '.hp-video-slider .hp-video-slider-item > a, .right-col-video-slider .right-col-slider-item > a', function (e) {
  if (!window.cookieConsent || !window.cookieConsent.allowedCategory('third_party')) {
    e.preventDefault();
    e.stopPropagation();
    window.open($(this).prop('href'), '_blank');
  }
})
