/* TODO: Debugovací skript */
/*document.addEventListener('DOMContentLoaded', function () {
  // Sledování kliků
  document.addEventListener('click', function (e) {
    console.log('Klik na:', {
      element: e.target,
      tagName: e.target.tagName,
      id: e.target.id,
      classes: e.target.className,
      text: e.target.textContent?.trim()
    });
  });

  // Sledování kláves
  document.addEventListener('keydown', function (e) {
    console.log('Stisknuta klávesa:', {
      key: e.key,
      code: e.code,
      element: e.target,
      tagName: e.target.tagName,
      id: e.target.id,
      classes: e.target.className
    });
  });
});*/

/* TODO: az bude presun na vite, tak dát do samostatného souboru */
/* detekce jazyka */

/*let pageLanguage;

function detectPageLanguage() {
  let lang = document.documentElement.lang;
  if (!lang) {
    const metaLang = document.querySelector('meta[http-equiv="Content-Language"]');
    if (metaLang) {
      lang = metaLang.getAttribute('content');
    }
  }

  if (!lang) {
    const metaOgLocale = document.querySelector('meta[property="og:locale"]');
    if (metaOgLocale) {
      lang = metaOgLocale.getAttribute('content');
    }
  }

  if (!lang) {
    const bodyText = document.body.innerText.toLowerCase();
    if (
      bodyText.includes('čeština') ||
      bodyText.includes('česky') ||
      document.title.includes('čeština') ||
      window.location.href.includes('.cz')
    ) {
      lang = 'cs';
    } else {
      lang = 'en'; // Výchozí hodnota, pokud se nepodařilo detekovat
    }
  }

  pageLanguage = lang;
  //console.log('Detekovaný jazyk stránky:', pageLanguage);
  return lang;
}

// incializace funkce
document.addEventListener('DOMContentLoaded', function() {
  detectPageLanguage();
});*/

const LanguageManager = (function() {
  let currentLang = '';
  
  function detect() {
    let lang = document.documentElement.lang;
    if (!lang) {
      const metaLang = document.querySelector('meta[http-equiv="Content-Language"]');
      if (metaLang) {
        lang = metaLang.getAttribute('content');
      }
    }

    if (!lang) {
      const metaOgLocale = document.querySelector('meta[property="og:locale"]');
      if (metaOgLocale) {
        lang = metaOgLocale.getAttribute('content');
      }
    }

    if (!lang) {
      const bodyText = document.body.innerText.toLowerCase();
      if (
        bodyText.includes('čeština') ||
        bodyText.includes('česky') ||
        document.title.includes('čeština') ||
        window.location.href.includes('.cz')
      ) {
        lang = 'cs';
      } else {
        lang = 'en'; // Výchozí hodnota, pokud se nepodařilo detekovat
      }
    }

    currentLang = lang;
    return lang;
  }

  function getLanguage() {
    return currentLang;
  }

  // Veřejné API modulu
  return {
    detect: detect,
    getLanguage: getLanguage
  };
})();

// incializace funkce
document.addEventListener('DOMContentLoaded', function() {
  LanguageManager.detect();
  //console.log('Detekovaný jazyk 1:', LanguageManager.getLanguage());
});